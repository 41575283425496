
import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { getRequestsToPay } from "../services/RequestsPendingToPayService"; // Asegúrate de importar correctamente tu servicio

// Definir estilos para el documento PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  title: {
    fontSize: 20,
    marginBottom: 20,
    textAlign: "center",
    color: "#333",
  },
  section: {
    marginBottom: 20,
    padding: 10,
  },
  tableTitle: {
    fontSize: 16,
    marginBottom: 10,
    textAlign: "left",
    fontWeight: "bold",
  },
  tableSubTitle: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "left",
    fontWeight: "bold",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
    overflow: "hidden",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    flex: 1, // Cada columna tiene el mismo ancho
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ddd",
    backgroundColor: "#f0f0f0",
    padding: 8,
  },
  tableCol: {
    flex: 1, // Cada columna tiene el mismo ancho
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ddd",
    padding: 8,
  },
  tableCellHeader: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
  },
  tableCell: {
    fontSize: 10,
    textAlign: "center",
  },
  footerRow: {
    backgroundColor: "#d4edda", // Color de fondo para el pie de tabla
    flexDirection: "row",
  },
  footerCell: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 10,
    padding: 8,
    flex: 1, // Cada celda del footer tiene el mismo ancho
  },
  footerCellEnd: {
    textAlign: "right",
    fontWeight: "bold",
    fontSize: 10,
    padding: 8,
    flex: 4, // Simula colspan
  },
  strikethrough: {
    textDecoration: "line-through",
    color: "red", // Color opcional para el tachado
  },
  boldText: {
    fontWeight: "bold",
  },
  signatureSection: {
    position: "absolute",
    bottom: 40,
    left: 20,
    right: 20,
    paddingTop: 10,
    fontSize: 12,
    textAlign: "center", // C
  },
});

// Crear el documento PDF utilizando componentes de @react-pdf/renderer
const MyDocument = ({ data }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.title}>Reporte de tiempo extra</Text>
      <View style={styles.section}>
        <Text style={styles.tableTitle}>Resumen</Text>
        <View style={styles.table}>
          {/* Encabezados de la tabla */}
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Nombre</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Cantidad</Text>
            </View>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Precio</Text>
            </View>
          </View>
          {/* Filas de datos de la tabla */}
          {data.requests.map((item) => (
            <View style={styles.tableRow} key={`summary-${item.id}`}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.user}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{item.hoursToPayTotal}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {(item.modifiedTotal !== null && item.modifiedTotal !== 0) && (
                    <Text style={styles.strikethrough}>
                      ${item.totalToPay}
                    </Text>
                  )}
                  <Text style={styles.boldText}>
                    ${item.modifiedTotal === null || item.modifiedTotal === 0 ? item.totalToPay : item.modifiedTotal}
                  </Text>
                </Text>
              </View>
            </View>
          ))}
          {/* Pie de tabla */}
          <View style={[styles.footerRow]}>
            <View style={styles.footerCell}>
              <Text>Totales:</Text>
            </View>
            <View style={styles.footerCell}>
              <Text>{data.totalHours}</Text>
            </View>
            <View style={styles.footerCell}>
              <Text>${data.totalToPay}</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
    {data.requests.map((item) => (
      <Page size="A4" style={styles.page} key={`detail-${item.id}`}>
        <Text style={styles.title}>{item.user}</Text>
   
        <View style={styles.section}>
          <View style={styles.table}>
            {/* Encabezados de la tabla */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Fecha</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Motivo</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Trabajo desde casa</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Horas solicitadas</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Horas a pagar</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Total a pagar por día</Text>
              </View>
            </View>
            {item.items.map((subItem, subIndex) => (
              <View style={styles.tableRow} key={`item-${subItem.id}-${subIndex}`}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{subItem.date} ({subItem.dayOfTheWeek})</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{subItem.reason}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{subItem.itsWorkFromHome ? "Sí" : "No"}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{subItem.hours}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{subItem.hoursToPay}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {/* Texto tachado si es necesario */}
                    {(subItem.modifiedPayment !== null && subItem.modifiedPayment !== 0) && (
                      <Text style={styles.strikethrough}>${subItem.moneyToPay} </Text>
                    )}
                    {/* Texto en negrita */}
                    <Text style={styles.boldText}>
                      ${subItem.modifiedPayment === null || subItem.modifiedPayment === 0 ? subItem.moneyToPay : subItem.modifiedPayment}
                    </Text>
                  </Text>
                </View>
              </View>
            ))}
            {item.formattedExtraPayments.map((subItem, subIndex) => (
            
              <View style={styles.tableRow} key={`extra-${subItem.id}-${subIndex}`}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{subItem.formattedDate}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{subItem.reason}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}></Text>
                </View>
                <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                <Text style={styles.boldText}>$ {subItem.pay}</Text>
              </Text>
            </View>
          </View>
        ))}
        <View style={[styles.footerRow]}>
          <View style={styles.footerCellEnd}>
            <Text>Total a pagar:</Text>
          </View>
          <View style={styles.footerCell}>
            <Text>{item.hoursToPayTotal}</Text>
          </View>
          <View style={styles.footerCell}>
            <Text>${item.modifiedTotal === null || item.modifiedTotal === 0 ? item.totalToPay : item.modifiedTotal}</Text>
          </View>
        </View>
      </View>
    </View>
    <Text style={styles.tableSubTitle}>¿Quien creo la solicitud?: {item.requester}</Text>
    <Text style={styles.tableSubTitle}>Primer aprobador: {item.approver}</Text>
    <Text style={styles.tableSubTitle}>Periodo: {item.startDate} - {item.endDate}</Text>
    <View style={styles.signatureSection}>
          <Text>______________________________________________________________</Text>
          <Text>Firma de recibido ({item.user})</Text>
        </View>
  </Page>
))}
  </Document>
);


// Crear un componente para proporcionar el enlace de descarga del PDF
const PDFLink = ({ data }) => (
  <div style={{ textAlign: "center", marginTop: "20px" }}>
    <PDFDownloadLink
      document={<MyDocument data={data} />}
      fileName="Reporte-Solicitudes-Pago.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? "Generando documento..." : "Descargar PDF"
      }
    </PDFDownloadLink>
  </div>
);

// Componente principal de la aplicación
function App() {
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const response = await getRequestsToPay();
        setProductData(response); // Asumimos que la respuesta tiene el formato esperado
      } catch (error) {
        console.error("Error al obtener datos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <h1>Dones del Mezcal</h1>
      <p>
        Haga clic en el siguiente enlace para descargar el documento en formato
        PDF.
      </p>
      {loading ? (
        <p>Cargando datos...</p>
      ) : (
        <PDFLink data={productData} />
      )}
    </div>
  );
}

export default App;