import { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { getActiveUsers } from '../services/OverTimeRequestService';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';


function OverTimeSelectUserModal({ show, handleClose }) {
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hideButton, setHideButton] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const navigate = useNavigate();
    const handleNext = () => {
        navigate(`/OverTimeRequest/0/${selectedUserId}`);
    };

    const handleChangeUserDd = (e) => {
        const { value } = e.target;
        const userId = parseInt(value);
        setSelectedUserId(userId);     
        setHideButton(userId === 0);
    };

    useEffect(() => {
        getActiveUsers().then((usersList) => {
            setUsers(usersList);
            setIsLoading(false);
        });
    }, []);

    return (
        <Modal size="md" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>¿A quién pertenece el tiempo extra? Selecciónalo.</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xl={12} className="">
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <Form.Group className="mt-3" controlId="formRole">
                                <Form.Control
                                    as="select"
                                    name="setSelectedUserId"
                                    value={selectedUserId} // Usar el valor del estado
                                    onChange={handleChangeUserDd}
                                >
                                    <option key={0} value={0}>
                                        Seleccionar usuario
                                    </option>
                                    {users.map((usr) => (
                                        <option key={usr.id} value={usr.id}>
                                            {usr.userName}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        )}
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                {!hideButton && (
                    <Button variant="success" onClick={handleNext}>
                        Siguiente
                    </Button>
                )}
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default OverTimeSelectUserModal;