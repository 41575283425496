export const getUserName = async(id) => {
    try {
        let url = `/api/users/getUserName/${id}`;
        const requestOptions = {
            method: 'GET', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
        };
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return [];
    }
    
}
 