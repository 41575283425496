
import { useState, useEffect } from 'react';
import {  Table, Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { getFormattedRequest } from '../services/RequestDetailsModalService'
 
const getUserAndRole = () => {
    let userId = localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')) : 0;
    let roleId = localStorage.getItem('roleId') ? parseInt(localStorage.getItem('roleId')) : 0;
    return { userId, roleId }
}

function RequestDetailsModal({ show, handleClose, requestId }) {
   const [roleId, setRoleId] = useState(0);
   const [data, setData] = useState({});

  useEffect(() => {
    if (requestId) {
        getFormattedRequest(requestId).then(dResult => {
            console.log(dResult)
            setData(dResult);
        })
    }
    let { userId, roleId } = getUserAndRole();
    setRoleId(roleId);
   }, [requestId]);

  return (
    <Modal size="md" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Detalles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
            <Col xl={12} className=''  >
                <Table  border={3} striped bordered hover responsive> 
                    <thead>
                        <tr>
                            <th className='text-center'>
                                Descripción
                            </th>
                            <th className='text-center'>
                                Valor
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='text-center'><b>Estatus</b></td>
                            <td  className='text-center'>{data.status}</td>
                        </tr>
                        <tr>
                            <td className='text-center'><b>Usuario</b></td>
                            <td  className='text-center'>{data.user}</td>
                        </tr>
                        <tr>
                            <td className='text-center'><b>Solicitante</b></td>
                            <td  className='text-center'>{data.requester}</td>
                        </tr>
                        <tr>
                            <td className='text-center'><b>Aprobador</b></td>
                            <td  className='text-center'>{data.approver}</td>
                        </tr>
                        <tr>
                            <td className='text-center'><b> Fecha de aprobación</b></td>
                            <td  className='text-center'>{data.approvalDate}</td>
                        </tr>
                        <tr>
                            <td className='text-center'><b>Periodo</b></td>
                            <td  className='text-center'>{data.startDate} - {data.endDate}</td>
                        </tr>
                        { roleId == 2 || roleId == 3 ? 
                            <tr>
                                <td className='text-center'><b>Costo por hora del empleado</b></td>
                                <td  className='text-center'>${data.hourCostOT}</td>
                            </tr>:
                            null    
                        }
                        
                       
                    </tbody>
                </Table> 
            </Col>
       </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RequestDetailsModal;
