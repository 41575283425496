import React, {  useState } from 'react'
import {  Button,Form, Spinner, Row, Col, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyRequestsTable from '../components/MyRequestsTable';
import Loading from '../components/Loading';
import OverTimeSelectUserModal from '../components/OverTimeSelectUserModal';



function OverTime(){        
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);    
    const [reload, setReload] = useState(0);
    const [registersQty, setRegistersQty] = useState(999);
    const [showModal, setShowModal] = useState(false);
    const handleRedirectToRequesOt = () => {
      setShowModal(true)
    }   
  return (
    <div>
      <h1>Tiempo extra - mis solicitudes</h1>
      {isLoading ? (
        <Loading />
      ) : (
        <>       
        <Row>
          <Col xl={3} >
          <Button onClick={handleRedirectToRequesOt} >Solicitar pago de tiempo extra</Button>
          </Col>
          <Col xl={5} >

          </Col>
          <Col xl={4} >
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
              <MyRequestsTable reload={reload} registersQty={registersQty}  />
          </Col>
        </Row>

        </>
      )}
        <ToastContainer  />
        <OverTimeSelectUserModal  show={showModal} handleClose={() => { setShowModal(false) }}  /> 
    </div>
  );
}

export default OverTime