import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


function RequestRecoverPassword() {
  const [email, setEmail] = useState('');
  const [showAlert, setShowAlert] = useState(false); 
  const [alertText, setAlertText] = useState(''); 
  const [alertText2, setAlertText2] = useState(''); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const handleBack = () =>{
    navigate('/login')
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);

    if (email === '') {        
        setShowAlert(true);
        setAlertText('El campo de correo electrónico no debe estar vacío.');
        setIsButtonDisabled(false);
      } else {
         
        setShowAlert(false);
        setAlertText(null);        
        const requestBody = {
            email: email
          };                    
          const requestOptions = {
            method: 'POST', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
            body: JSON.stringify(requestBody) // Cuerpo de la solicitud convertido a JSON
          };
          fetch("/api/auth/resetPassword", requestOptions)
          .then(response => response.json())
          .then(data => {
            setShowAlert(true);
            setAlertText('Gracias por solicitar el reinicio de contraseña. Recibirás un correo con instrucciones para restablecer tu contraseña. ');
            setAlertText2('Si no encuentra nuestro correo en su bandeja de entrada, por favor revise su carpeta de correo no deseado, spam o junk. ');
            setIsButtonDisabled(true);
          })
          .catch(error => {            
            console.error('Error al realizar la solicitud:', error);
          });
      }

  };
  return (
    <div className="bg-dark d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Container >
        <Row className="justify-content-center">
            <Col xl={6} className='text-center'>
                <h2 className="text-center text-white mb-4">Recuperar contraseña</h2>        
            </Col>
        </Row>
        <Row className="justify-content-center mt-1">
            <Col xl={3} className='text-center'>
                <Alert key='warning' variant='success' show={showAlert}>
                    {alertText}
                    <br className='pt-2'>
                    </br>
                    {alertText2}
                </Alert>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xl={3} className='text-center'>
            <Form className='' onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label className='text-white'>Correo electrónico</Form.Label>
                    <Form.Control
                    type="email"
                    placeholder="Ingrese su correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}                   
                    />               
                </Form.Group>
                <Button className='mt-4' variant="primary" type="submit"  disabled={isButtonDisabled} >Solicitar</Button>
             </Form>
            </Col>
        </Row>
     
        <Row className='mt-2'>
          <Col className='text-center'>
          <Button className='mt-5' variant="link" type="button" onClick={handleBack}  >Regresar</Button>
          </Col>
        </Row>
         
      </Container>
    </div>
  );
}

export default RequestRecoverPassword;
