import { Card, Button, Row, Col, ListGroup } from 'react-bootstrap';

function HomeRouteCard({ element }) {
  return (
    <Card  border="secondary" className='m-2' >

      <Card.Body>
        <Row>
            <Col>
                <Card.Title>{element.title}</Card.Title>
            </Col>
        </Row>
        {
            element.description ?
            (
                <Row>
                    <Col>
                        <Card.Text>
                        <b>Funcionalidades:</b> {element.description}
                        </Card.Text>
                    </Col>
                </Row>
            ) :
            null
        }
        {
            element.mainUrl ?
            (
                <Row className='mt-3 mb-3'>
                    <Col className='text-center' >
                    <   Button variant="primary" href={element.mainUrl} >Ir a la página</Button>
                    </Col>
                </Row>
            ) :
            null
        }
       
        {
            element.features == 0 ?
             null :
            (
            <Row>
                <Col>
                <h6>
                    { element.mainUrl ? "Otros enlaces" : "Enlaces" }
                    
                </h6>
                <ListGroup className="list-group-flush">
                    { element.features.map((child, childIndex) => (
                        <ListGroup.Item><Card.Link href={child.url}>{child.name}</Card.Link></ListGroup.Item>
                    ))}
                </ListGroup>
                </Col>
            </Row>
            )
        }
    
      </Card.Body>
    </Card>
  );
}

export default HomeRouteCard;