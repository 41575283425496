import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false); 
  const [alertText, setAlertText] = useState(''); 
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const handleRecoverPassword = () =>{
    navigate('/requestRecoverPassword')
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);

    if (email === '' || password === '') {        
        setShowAlert(true);
        setAlertText('Los campos de correo electrónico y contraseña no pueden estar vacíos.');
        setIsButtonDisabled(false);
      } else {
        setShowAlert(false);
        setAlertText(null);        
        const requestBody = {
            username: email,
            password: password
          };                    
          const requestOptions = {
            method: 'POST', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
            body: JSON.stringify(requestBody) // Cuerpo de la solicitud convertido a JSON
          };
          fetch("/api/auth/signin", requestOptions)
          .then(response => response.json())
          .then(data => {
           if(data.result)
           {
                localStorage.setItem('token', data.token);
                localStorage.setItem('userId', data.userId);
                localStorage.setItem('userName', data.userName);
                localStorage.setItem('roleId', data.roleId);                
                navigate("/");
           }           
           else
           {
                setShowAlert(true);
                setAlertText(data.msg);
                setIsButtonDisabled(false);
           }
          })
          .catch(error => {            
            console.error('Error al realizar la solicitud:', error);
          });
      }

  };
  return (
    <div className="bg-dark d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <Container >
        <Row className="justify-content-center">
            <Col xl={3} className='text-center'>
                <h2 className="text-center text-white mb-4">Iniciar sesión</h2>        
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col xl={3} className='text-center'>
            <Form className='' onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Correo electrónico</Form.Label>
                    <Form.Control
                    type="email"
                    placeholder="Ingrese su correo electrónico"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}                   
                    />               
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                    type="password"
                    placeholder="Ingrese su contraseña"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}                    
                    />
                </Form.Group>
                <Button className='mt-4' variant="primary" type="submit"  disabled={isButtonDisabled} >Iniciar sesión</Button>
             </Form>
            </Col>
        </Row>
        <Row className='mt-2'>
          <Col className='text-center'>
          <Button className='mt-5' variant="link" type="button" onClick={handleRecoverPassword}  >Recuperar contraseña</Button>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
            <Col xl={3} className='text-center'>
                <Alert key='warning' variant='warning' show={showAlert}>
                    {alertText}
                </Alert>
            </Col>
        </Row> 
      </Container>
    </div>
  );
}

export default Login;
