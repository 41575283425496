import React, { useEffect, useState } from 'react';
import { Offcanvas, Table, Button,  Row, Col, Container } from 'react-bootstrap';
import PeriodsList from '../components/PeriodsList';
import PaymentByPeriodTable from '../components/PaymentByPeriodTable';
import { getGroupedDates } from '../services/OverTimeRequestService';

function GeneralPaymentReport(){
    const setting = { style: 'currency', currency: 'MXN', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const [show, setShow] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [dates, setDates] = useState([]);
    const [paymentData, setPaymentData] = useState([]);
    const [totalTotal, setTotalTotal] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const updatePaymentData = (data) => {
        setPaymentData(prevData => {
            const existingIndex = prevData.findIndex(item => item.period === data.period);
            if (existingIndex !== -1) {
                const updatedData = [...prevData];
                updatedData[existingIndex] = data;
                return updatedData;
            } else {                
                return [...prevData, data];
            }
        });
    };
    useEffect(() => {
        getGroupedDates().then((datesList) => {    
            setDates(datesList);
            setCheckedItems([]);  // Inicializar el array vacío
        });
    }, []);
    useEffect(()=> {        
        const updatedPaymentData = paymentData.filter(item => checkedItems.includes(item.dateValue));
        if (updatedPaymentData.length !== paymentData.length) {
            setPaymentData(updatedPaymentData);
        }
    }, [checkedItems, paymentData]);
    useEffect(() => {
        // Recalcular el total cuando paymentData cambie
        let subTotal = 0;
        for(let pd of paymentData) {
            subTotal += pd.totalToPay;
        }
        setTotalTotal(subTotal);
    }, [paymentData]);
    return (
        <>
        <Container fluid>
            <Row>
                <Col md={3}>
                <Button variant="primary" onClick={handleShow}>
                    Filtros
                </Button>
                </Col>
                <Col md={6} className="text-center" >
                    <h2>Reporte general de pagos</h2>
                </Col>
                <Col md={3}>
                </Col>
            </Row>
            {
                paymentData && paymentData.length > 0 ?
                (
                    <Row className='mb-5 mt-4'>
                        <Col md={12}>
                            <Table striped bordered>
                                <thead>
                                    <tr class="table-dark"> 
                                        <td className='text-center' colSpan={2}>
                                            <h4>Resumen</h4>    
                                        </td>
                                    </tr>
                                    <tr> 
                                        <td className='text-center'>Periodo</td>
                                        <td className='text-center'>Total del periodo</td>
                                    </tr>
                                </thead>
                                <tbody>
                                   {   
                                        paymentData.map((pdItem, pdItemIndex) => (
                                            <tr key={pdItemIndex}>
                                                <td className='text-center'>{pdItem.period}</td>
                                                <td className='text-center'>{ pdItem.totalToPay.toLocaleString('es-MX',setting) }</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot>
                                    <tr class="table-primary" >
                                        <td colSpan={2} className="text-center">
                                           <h5>Gran total: {totalTotal.toLocaleString('es-MX',setting) }</h5>
                                        </td>
                                    </tr>
                                </tfoot>
                            </Table>
                        </Col>
                    </Row>
                ) :
                null
            }
         
     
            {
                checkedItems && checkedItems.length > 0 ?
                (checkedItems.map((dt, dtIndex) => (   
                                <PaymentByPeriodTable  key={dtIndex}  dateValue={dt}  onUpdatePaymentData={updatePaymentData}  />
                )))
                :null
                            
            }

        </Container>
            <Offcanvas show={show} onHide={handleClose}>
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>Filtros</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <PeriodsList checkedItems={checkedItems} setCheckedItems={setCheckedItems}  dates={dates} />
            </Offcanvas.Body>
        </Offcanvas>
      </>
    )
}
export default GeneralPaymentReport