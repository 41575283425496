

export const getExtraPaymentToEdit = async(id) => {
    try {
        const response = await fetch(`/api/overtime/getExtraPaymentToEdit/${id}`);
        return await response.json();
      } catch (error) {
          return {};
      } 
}


export const updateExtraPayment = async(formData) => {
    const url =  "/api/overtime/updateExtraPayment";       
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };
    try {
        const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        return { result: false }
    }
}