
import { useState, useEffect } from 'react';
import { Button, Table, Row, Col, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';

const getUserId = () => localStorage.getItem('userId') ?? 0;
function ChangeItemPaymentModal({ show, handleClose, itemId, handleReload }) {
  let userId = getUserId();
  const [data, setData] = useState(null);    
  const [newPay, setNewPay] = useState("");
  const [reason, setReason] = useState("");


  const handleUpdateNewPay = () =>{
    let inputsPending = [];
    if (isNaN(newPay) || newPay === '') {
        inputsPending.push("El campo 'Nuevo pago' está vacío o tiene un valor no válido")
    }
    if(!reason)
    {
        inputsPending.push("El campo 'Motivo' no debe estar vacío");
    }
    if(inputsPending.length == 0)
    {
        let obj = {
            itemId, reason, userId: parseInt(userId), pay: parseFloat(newPay)
        }
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud    
            body: JSON.stringify(obj)       
          };
        fetch(`/api/overtime/updatePayment`, requestOptions)
        .then(response => response.json())
        .then(data => {                          
        if(data.result)
        {
            toast.success('Solicitud eliminada correctamente');
            handleClose();
            handleReload();
   
        }           
        else
        {
            toast.error('Lo sentimos, algo salió mal.');
        }
        })
        .catch(error => {            
            toast.error('Lo sentimos, algo salió mal.');
        });    
    }
    else
    {
        toast.warning(`Lo sentimos, debes completar los siguientes campos: ${inputsPending.join(', ')}`)
    }
  }
  const handleReason = (e) => {
    const { value } = e.target;
    setReason(value);
  }
  const handleNewPayChange = (e) => {
    const { value } = e.target;
    if (!isNaN(value) && value !== '') {
        setNewPay(value);
    } else {
        setNewPay('0');
    }
};

  useEffect(() => {
    if (itemId) {
      fetch(`/api/overtime/getSingleItem/${itemId}`)
        .then(response => response.json())
        .then(data => setData(data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [itemId]);

  return (
    <Modal size="xl" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Movimientos de la Solicitud</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
             <>
              <Row>
                <Col xl={12}>
                <Table className='mb-5 mt-3' border={3} striped bordered hover responsive>
                    <thead>
                        <tr className='table-primary'>
                            <th className='text-center' >   
                                Fecha
                            </th>
                            <th className='text-center' >   
                            Motivo
                            </th>
                            <th className='text-center' >   
                            Trabajo desde casa
                            </th>
                            <th className='text-center' >   
                            Horas solicitadas
                            </th>
                            <th className='text-center' >   
                            Horas a pagar	
                            </th>
                            <th className='text-center' >   
                            Total a pagar por día	
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='text-center'>
                                {data.date}
                            </td>
                            <td className='text-center'>
                                {data.reason}
                            </td>
                            <td className='text-center'>
                                {data.itsWorkFromHome ? "Si" : "No"}
                            </td>
                            <td className='text-center'>
                                {data.hours}
                            </td>
                            <td className='text-center'>
                                {data.hoursToPay}
                            </td>
                            <td className='text-center'>
                                {data.modifiedPayment == null ? null : <p className="text-decoration-line-through">$ {data.moneyToPay}</p>}
                                $ {data.modifiedPayment == null ? data.moneyToPay : data.modifiedPayment}
                            </td>
                        </tr>
                    </tbody>
                 </Table>
                </Col>
              </Row>
              <Row>
                <Col xl={3} className=' p-2'  >
                <Form.Label>Nuevo pago:</Form.Label>
                    <Form.Group controlId="formEmail">
                  
                    <Form.Control
                        type="text"
                        name=""
                        placeholder='$ Únicamente números'
                        value={newPay}
                        onChange={handleNewPayChange}
                    />
                    </Form.Group>
                </Col>
                <Col xl={3} className=' p-2'  >
                <Form.Label>Ingrese razón del movimiento :</Form.Label>
                    <Form.Group controlId="formEmail">
                  
                    <Form.Control
                        type="text"
                        name=""
                        placeholder='Motivo'
                        value={reason}
                        onChange={handleReason}
                        required
                    />
                    </Form.Group>
                </Col>
             
              </Row>
              </>
        ) : (
          'Cargando datos...'
        )}
      </Modal.Body>
      <Modal.Footer>
      <Button onClick={handleUpdateNewPay} variant='success'>Actualizar pago por dia</Button>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeItemPaymentModal;
