

export const getItemToEdit = async(id) => {
    try {
        const response = await fetch(`/api/overtime/getSingleItemToEdit/${id}`);
        return await response.json();
      } catch (error) {
          return {};
      } 
}

export const updateItem = async(formData) => {
    const url =  "/api/overtime/updateItem";       
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };
    try {
        const response = await fetch(url, requestOptions);
       return await response.json(); 
    } catch (error) {
        return { result: false }
    }
}