import React, { useEffect, useState } from 'react';
import { Table, Button, ListGroup, Badge, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import RequestMovements from '../components/RequestMovements'
import Loading from '../components/Loading';

const getUserId = () => localStorage.getItem('userId') ?? 0;
const MyRequestsTable = ({  reload, registersQty }) => {
    const navigate = useNavigate();
    let userIdFromSesion = parseInt(getUserId());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentRequestId, setCurrentRequestId] = useState(null);
    const [showMovements, setShowMovements] = useState(false);
    const handleEdit = (id) => {
        navigate(`/overtimerequest/${id}`);
    }

    const handleMovements = (id) => {
        setCurrentRequestId(id);
        setShowMovements(true);
    }

    const handleDelete =  (id) => {
        const requestOptions = {
            method: 'DELETE', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud           
          };
        fetch(`/api/overtime/deleteRequest/${id}`, requestOptions)
        .then(response => response.json())
        .then(data => {                          
        if(data.result)
        {
            toast.success('Solicitud eliminada correctamente');
            fetchData();
        }           
        else
        {
            toast.error('Lo sentimos, algo salió mal.');
        }
        })
        .catch(error => {            
            toast.error('Lo sentimos, algo salió mal.');
        });    
    }

    const fetchData = () => {        
        setLoading(true);
        setError(null);
        fetch(`/api/overtime/getRequestsByUserId/${userIdFromSesion}/${registersQty}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(list => {
                console.log('Data fetched:', list);
                setData(list);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

   

    useEffect(() => {
        fetchData();
    }, [userIdFromSesion, reload]);
    if (loading) return <Loading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div>
            
            { 
            data.length == 0 ? (
                <Row className='mt-5'>
                    <Col className='text-center'>
                        <h3>Aun no has solicitado tiempo extra</h3>
                    </Col>
                </Row>
            ) 
            : 
            data.requests.map((item, index) => (
                <>
                <Row>
                    <Col xl={12} >
                        <Table className='mb-5 mt-3' striped bordered hover>
                            <thead>
                                <tr className='table-primary'>
                                    <th colSpan={5}>   
                                        <Row>
                                        <Col md={4} className="text-start" >
                                                <h6>
                                                    Usuario <Badge bg="secondary">{item.user}</Badge>
                                                </h6>
                                            </Col>
                                            <Col md={4} className="text-center" >
                                                <h5>
                                                    Estatus <Badge bg={item.statusColor}>  {item.status}</Badge>
                                                </h5>   
                                            </Col>
                                            <Col md={4} >
                                                <h5>
                                                    Periodo <Badge bg="secondary"> {item.startDate} - {item.endDate}</Badge>
                                                </h5>   
                                            </Col>
                                        
                                        </Row>                             
                                                                    
                                    </th>
                                </tr>
                                <tr>
                                    <th className='text-center'>Fecha</th>
                                    <th className='text-center'>Motivo</th>
                                    <th className='text-center'>Horas</th>
                                    <th className='text-center'>Trabajo desde casa</th>
                                    <th className='text-center'>A pagar</th>
                                </tr>
                            </thead>   
                            <tbody>  
                                {item.items.map((subItem, subIndex) => (            
                                        <tr key={subIndex}>
                                                <td className='text-center'>{subItem.date} </td>
                                                <td className='text-center'>{subItem.reason}</td>
                                                <td className='text-center'>{subItem.hours}</td>
                                                <td className='text-center'> {subItem.itsWorkFromHome ? "Si" : "No"}</td> 
                                                <td className='text-center'>No disponible</td>                                                                                                                                                                                                                            
                                        </tr>
                                ))}
                                 {item.formattedExtraPayments.map((subItem, subIndex) => (            
                                        <tr key={subIndex}>
                                                <td className='text-center'>{subItem.formattedDate} </td>
                                                <td className='text-center'>{subItem.reason}</td>
                                                <td className='text-center'>N/A</td>
                                                <td className='text-center'>N/A</td>
                                                <td className='text-center'>$ {subItem.pay}</td>                                                                                                                                                                                                                           
                                        </tr>
                                ))}

                            </tbody>          
                            <tfoot>                            
                                {
                                    (item.statusId == 4 || item.statusId == 5) ? 
                                    (           
                                        <tr>                      
                                            <td colSpan={5}>
                                            {
                                                    item.hasMovements ? (
                                                    <Button variant='info' onClick={() => handleMovements(item.id)}  >Ver movimientos</Button>
                                                    ) : null
                                                }
                                                
                                            </td>
                                         </tr>                                                                                       
                                    ) 
                                    :
                                    (
                                        <tr>
                                            <td colSpan={5}>
                                                <Button variant='warning' className='m-1' onClick={() => handleEdit(item.id)}  >Editar</Button>
                                                <Button variant='danger' className='m-1' onClick={() => handleDelete(item.id)}  >Eliminar solicitud</Button>
                                                {
                                                    item.hasMovements ? (
                                                    <Button variant='info' className='m-1' onClick={() => handleMovements(item.id)}  >Ver movimientos</Button>
                                                    ) : null
                                                } 
                                            </td>
                                         
                                        </tr>
                                    )
                                }                               
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                <hr></hr>
                </>
            ))} 
            <RequestMovements
                show={showMovements}
                handleClose={() => setShowMovements(false)}
                requestId={currentRequestId}
            />
        </div>
    );
};

export default MyRequestsTable;
