
import { useState, useEffect } from 'react';
import { Button, Table, Row, Col, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { getItemToEdit, updateItem } from '../services/EditItemModalService';
import Loading from '../components/Loading';


const getUserId = () => localStorage.getItem('userId') ?? 0;

function EditItemModal({ show, handleClose, itemId }) {
   let userId = getUserId();
   const [loadingModal, setLoadingModal] = useState(true);
  const [formData, setFormData] = useState({
    id:itemId,
    formattedDate: '',
    reason: '',
    hours: 0,
    workFromHome:false,
    comments:'',
    userId
  })

  const handleUpdateItem = () => {
    let pendingInputs = [];
    if(!formData.reason)
    {
        pendingInputs.push("Motivo");
    }
    if(!formData.hours)
    {
        pendingInputs.push("Horas");
    }
    if(!formData.comments)
    {
        pendingInputs.push("Comentarios");
    }
    if(pendingInputs.length == 0)
    {
        updateItem(formData).then(() => {
            toast.success('Solicitud actualizada correctamente');
            handleClose()
         
        })
    }
    else
    {
        toast.warning(`Lo sentimos, debes completar los siguientes campos: ${pendingInputs.join(', ')}`)
    }
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  useEffect(() => {
        if(itemId)
        {
            getItemToEdit(itemId).then(itemR => {
                setFormData({
                    id: itemR.id,
                    formattedDate: itemR.formattedDate,
                    reason: itemR.reason,
                    hours: itemR.hours,
                    workFromHome: itemR.itsWorkFromHome,
                    comments: '',
                    userId
                })  
            }).finally(()=>{
                setLoadingModal(false);
            })
        }
  }, [itemId]);
  return (
    <Modal size="md" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Editar solicitud</Modal.Title>
      </Modal.Header>
      <Modal.Body>
            {
                loadingModal ?
               <Loading />
                :
                <>
                <Row className='mt-3'>
                <Col >
                    <Form.Group className='' controlId="">
                                    <Form.Label>Fecha del tiempo extra:</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="formattedDate"
                                        value={formData.formattedDate}
                                        onChange={handleInputChange}
                                    />
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-3' >
                <Col >
                    <Form.Group className='' controlId="">
                                    <Form.Label>Motivo:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="reason"
                                        value={formData.reason}
                                        onChange={handleInputChange}
                                    />
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-3' >
                <Col >
                    <Form.Group className='' controlId="">
                                    <Form.Label>Horas:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="hours"
                                        value={formData.hours}
                                        onChange={handleInputChange}
                                    />
                    </Form.Group>
                </Col>
            </Row>
            <Row className='mt-3' >
                <Col >
                        <Form.Group className='mt-3' controlId="formWorkFromHome">
                            <Form.Label>Trabajo desde casa:</Form.Label>
                            <Form.Check
                                type="radio"
                                label="Sí"
                                name="workFromHome"
                                value="true"
                                checked={formData.workFromHome === true}
                                onChange={() => setFormData({ ...formData, workFromHome: true })}
                            />
                            <Form.Check
                                type="radio"
                                label="No"
                                name="workFromHome"
                                value="false"
                                checked={formData.workFromHome === false}
                                onChange={() => setFormData({ ...formData, workFromHome: false })}
                            />
                        </Form.Group>
                </Col>
            </Row>
            <Row className='mt-3' >
                <Col >
                    <Form.Group className='' controlId="">
                                    <Form.Label>Comentario:</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="comments"
                                        placeholder='Ingresar razón del cambio'
                                        value={formData.comments}
                                        onChange={handleInputChange}
                                    />
                    </Form.Group>
                </Col>
            </Row></>
            }

             

      </Modal.Body>
      <Modal.Footer>
      <Button variant="success" onClick={handleUpdateItem}>
          Guardar cambios
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditItemModal;
