import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const ResetPassword = () => {
    const { id } = useParams();
    const [password, setPassword] = useState('');
    const [pid, setPid] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState(null);
    const [validLink, setValidLink] = useState(0);
    const navigate = useNavigate();      
    let errors = {
        uppercase: { regex: /[A-Z]/, description: 'Al menos una letra mayúscula' },
        lowercase: { regex: /[a-z]/, description: 'Al menos una letra minúscula' },
        digit: { regex: /[0-9]/, description: 'Al menos un dígito' },
        special: { regex: /[^A-Za-z0-9]/, description: 'Al menos un símbolo especial' },
        length: { test: e => e.length > 4, description: 'Debe tener más de 4 caracteres' },
    };

    const validatePassword = (e) => {
        return Object.entries(errors).flatMap(([name, { test, regex, description }]) => {
            const isValid = test ? test(e) : regex.test(e);
            return isValid ? [] : { description, name };
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!password) {
            setMessage('La contraseña no debe estar vacía');
        } else {
            let validatePasswordResult = validatePassword(password);
            if (validatePasswordResult.length === 0) {
                setMessage('');
                
                const requestOptions = {
                    method: 'PUT', // Método de la solicitud
                    headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
                    body: JSON.stringify({ id: parseInt(pid), pwd: password }) // Cuerpo de la solicitud convertido a JSON
                  };
                fetch("/api/auth/updatePassword", requestOptions)
                .then(response => response.json())
                .then(data => {                           
                    if(data.result)
                    {
                        toast.success('La contraseña ha sido cambiada');
                        setValidLink(2);
                    }           
                    else
                    {
                        toast.error('Lo sentimos, algo salió mal.');
                    }
                })
                .catch(error => {            
                    toast.error('Lo sentimos, algo salió mal.');
                });   
            } else {
                let descriptions = validatePasswordResult.map(item => item.description);
                let descriptionsString = descriptions.join(', ');
                setMessage(descriptionsString);
            }
        }
    };
    const handleLoginRedirect = () => {
        navigate('/login')
    }
    const handleResetPwdRedirect = () => {
        navigate('/requestRecoverPassword')
    }
    const checkIfHasARequest = async () => {
        const response = await fetch(`/api/auth/checkIfUserHasAPwdRequest/${id}`);
        const data = await response.json();
        return data.result;
    }
    useEffect(() => {
        setPid(id);
        checkIfHasARequest().then(result => {
            if(result)
            {
                setValidLink(1);
            }
        })
    },[])
    return (
        <div className="bg-dark d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            { 
             validLink == 0 ? 
             (
                <Container>
                <Row className="justify-content-center">
                    <Col xl={12} className="text-center">
                        <h2 className="text-center text-white mb-4">El enlace de restablecimiento de contraseña ya fue usado o no es válido. Por favor, solicita un nuevo enlace de restablecimiento de contraseña.
                        </h2>
                        <Button  variant='link' onClick={handleLoginRedirect} >Iniciar sesión</Button>
                        <Button  variant='link' onClick={handleResetPwdRedirect}  >Recuperar contraseña</Button>
                    </Col>
                </Row>
                </Container>
              ) : 
            (
             validLink == 1 ?
             (<Container>
                <Row className="justify-content-center">
                    <Col xl={12} className="text-center">
                        <h2 className="text-center text-white mb-4">Restablecer Contraseña</h2>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl={3} className="text-center">
                        <Form onSubmit={handleSubmit}>
                        <Row className='mt-1'>
                                <Col>
                                    <Form.Group controlId="formPassword">
                                    <Form.Label className="text-white mb-4">
                                            - Al menos una letra mayúscula
                                            <br />
                                            - Al menos una letra minúscula
                                            <br />
                                            - Al menos un dígito
                                            <br />
                                            - Al menos un símbolo especial
                                            <br />
                                            - Debe tener más de 4 caracteres
                                        </Form.Label>
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Ingresa tu nueva contraseña"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>
                                <Col  xl={8} xs={7} >
                                    <Form.Group controlId="formShowPassword">
                                        <Form.Check
                                            type="checkbox"
                                            label="Mostrar contraseña"
                                            className="text-white"
                                            checked={showPassword}
                                            onChange={(e) => setShowPassword(e.target.checked)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button className="mt-4" variant="primary" type="submit">
                                        Restablecer Contraseña
                                    </Button>
                                </Col>
                            </Row>

                           

                           
                        </Form>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-4">
                    <Col xl={3} className="text-center">
                        {message && <Alert key="info" variant="info">{message}</Alert>}
                    </Col>
                </Row>
            </Container>):
            (
                <Container>
                <Row className="justify-content-center">
                    <Col xl={12} className="text-center">
                        <h2 className="text-center text-white mb-4">La contraseña ha sido cambiada exitosamente..
                        </h2>
                        <Button  variant='link' onClick={handleLoginRedirect} >Iniciar sesión</Button>
                    </Col>
                </Row>
                </Container>
            )
            
              ) 
             
            }
               <ToastContainer />
        </div>
    );
};

export default ResetPassword;
