
import { toast } from 'react-toastify';

export const sendForApproval = async(requestId, requesterId) => 
{
    let url = `/api/overtime/sendForApproval/${requestId}/${requesterId}`;
    const requestOptions = {
        method: 'PUT', 
        headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud         
      };
    const response = await fetch(url, requestOptions);
    return await response.json(); 
}


export const getFormattedRequest = async (requestId) => {
    try {
        
      const response = await fetch(`/api/overtime/getFormattedRequest/${requestId}`);
      return await response.json();
    } catch (error) {
        return [];
    } 
};

export const getActiveUsers = async () => {
    try {
        
      const response = await fetch(`/api/users/getActiveUsers`);
      return await response.json();
    } catch (error) {
        return [];
    } 
};




export const fetchQtyOfItemsByRequest = async (id) => {
    try {
        
      const response = await fetch(`/api/overtime/getQtyOfItemsByRequest/${id}`);
      const data = await response.json();
      return data.result ? (data.qty == 0 ? true : false) : true;
      
    } catch (error) {
        toast.error('Lo sentimos, algo salió mal.');
        return true;        
    } 
};

export const fetchDatesBetween = async () => {
    try {
      const response = await fetch(`/api/generalQueries/getWednesdays`);
      return await response.json();      
    } catch (error) {
        toast.error('Lo sentimos, algo salió mal.');
        return {
            "previousWednesday": null,
            "previousWednesdayFormatted": null,
            "nextWednesday": null,
            "nextWednesdayFormatted": null,
            "currentDate": null,
            "currentDateFormatted": null,
            };        
    } 
  };


  export const fetchRequestById = async (id) => {
    try {
      const response = await fetch(`/api/overtime/getRequestById/${id}`);
      return await response.json();               
    } catch (error) {
        return null;
    } 
  };



  export const returnToDraft = async(requestId, userId) => {
        let url = `/api/overtime/returnToDraft/${requestId}/${userId}`;
        const requestOptions = {
            method: 'PUT', 
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud         
        };
        const response = await fetch(url, requestOptions);
        return await response.json(); 

        // fetch(url, requestOptions)
        // .then(response => response.json())
        // .then(data => {                          
        // if(data.result)
        // {
        //     return true;
        // }           
        // else
        // {
        //     toast.error('Lo sentimos, algo salió mal.');
        //     return false;
        // }
        // })
        // .catch(error => {            
        //     toast.error('Lo sentimos, algo salió mal.');
        //     return false;
        // });              
  }


  export const insertItem = async (existId, requestId, formData) => {
            const url = existId ? "/api/overtime/insertItem" : "/api/overtime/insertFirstItem";        
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData)
            };
            try {
                const response = await fetch(url, requestOptions);
               return await response.json(); 
            } catch (error) {
                return { result: false, requestId }
            }
    
  }




export const getGroupedDates = async() => 
{
    let url = `/api/overtime/getGroupedDates`;
    const requestOptions = {
        method: 'GET', 
        headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud         
      };
    const response = await fetch(url, requestOptions);
    return await response.json(); 
}
