import React, { useEffect, useState } from 'react'
import {  Button, Spinner, Row, Col, Form, Badge } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddUser(){
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [approvers1, setApprovers1] = useState([]);
    const [approvers2, setApprovers2] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        username: '',
        roleId: 0,
        approver1:0,
        approver2:0,
        weeklyPayment: '0',
        otHourCost: '0'
      });

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
      const redirectBack = () => {
        navigate(`/users`);
      }

      const handleSubmit = (e) => {
        e.preventDefault();    
        console.log(formData)   
        let pendingInputs = [];
        let email = formData.email;
        let userName = formData.username;   
        let roleId = parseInt(formData.roleId);   
        let approver1= parseInt(formData.approver1);
        let approver2 = parseInt(formData.approver2);
        let otHourCost = formData.otHourCost ? (parseFloat(formData.otHourCost) == NaN ? 0 : parseFloat(formData.otHourCost)) : 0;
        let weeklyPayment = formData.weeklyPayment ? (parseFloat(formData.weeklyPayment) == NaN ? 0 : parseFloat(formData.weeklyPayment)) : 0;
        console.log("result", { email, userName, roleId, approver1, approver2, otHourCost, weeklyPayment })
        if(!email)
        {
            pendingInputs.push("Email");
        }
        if(!userName)
        {
            pendingInputs.push("Nombre del usuario");
        }
        if(roleId == 0)
        {
            pendingInputs.push("Rol");
        }
        if(pendingInputs.length == 0)
        {
            const requestOptions = {
                method: 'POST', // Método de la solicitud
                headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
                body: JSON.stringify({ email, userName, roleId, approver1, approver2, otHourCost, weeklyPayment }) // Cuerpo de la solicitud convertido a JSON
              };
            fetch("/api/users/addUser", requestOptions)
            .then(response => response.json())
            .then(data => {                           
                if(data.result)
                {
                    toast.success('Usuario creado correctamente');
                    setTimeout(() => {
                        navigate('/users'); // Redirigir después de 3 segundos
                    }, 1500); 
                }           
                else
                {
                    toast.error('Lo sentimos, algo salió mal.');
                }
            })
            .catch(error => {            
                toast.error('Lo sentimos, algo salió mal.');
            });         
        }
        else
        {
            toast.warning(`Lo sentimos, debes completar los siguientes campos: ${pendingInputs.join(', ')}`)
        }
    }

    useEffect(() => {
        
       
        const fetchRoles = async () => {
            try {
              const response = await fetch(`/api/generalQueries/getRoles`);
              const data = await response.json();
              console.log(data)
              setRoles(data);
            } catch (error) {
                toast.error('Lo sentimos, algo salió mal.');
            } finally {
              setIsLoading(false);
            }
          };
        fetchRoles();

        const fetchApprovers = async () => {
            try {
              const response = await fetch(`/api/users/getApproversAndAdmins`);
              const data = await response.json();
              console.log(data)
              setApprovers1(data);
              setApprovers2(data);
            } catch (error) {
                toast.error('Lo sentimos, algo salió mal.');
            } finally {
              setIsLoading(false);
            }
          };
          fetchApprovers();

    }, [])
  return (
    <div>
      { isLoading ? (
        <Spinner animation="border" role="status" />
      ) : (

        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email *:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            
          />
        <h5 className='mt-3'>
        Contraseña por defecto: <Badge bg="secondary">N0m1n@2024</Badge>
      </h5>
        </Form.Group>
        <Form.Group className='mt-3' controlId="formUsername">
          <Form.Label>Username *:</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
        
          />
        </Form.Group>

        
        <Form.Group className='mt-3' controlId="formWeeklyPayment">
          <Form.Label>Pago semanal *:</Form.Label>
          <Form.Control
            type="text"
            name="weeklyPayment"
            value={formData.weeklyPayment}
            onChange={handleInputChange}
            
          />
        </Form.Group>


        <Form.Group className='mt-3' controlId="formOtHourCost">
          <Form.Label>Costo del tiempo extra (Hora) *:</Form.Label>
          <Form.Control
            type="text"
            name="otHourCost"
            value={formData.otHourCost}
            onChange={handleInputChange}
            
          />
        </Form.Group>
        
        <Form.Group className='mt-3' controlId="formRole">
          <Form.Label>Rol *:</Form.Label>
          <Form.Control
            as="select"
            name="roleId"
            value={formData.roleId}
            onChange={handleInputChange}
          >
             <option key={0} value={0}>Seleccionar rol</option>
           {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className='mt-3' controlId="formRole">
          <Form.Label>Aprobador de tiempo extra *:</Form.Label>
          <Form.Control
            as="select"
            name="approver1"
            value={formData.approver1}
            onChange={handleInputChange}
          >
             <option key={0} value={0}>Seleccionar aprobador</option>
           {approvers1.map((approver) => (
                <option key={approver.id} value={approver.id}>{approver.userName} - {approver.userRoles.name}</option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className='mt-3' controlId="formRole">
          <Form.Label>Aprobador de tiempo extra (Respaldo):</Form.Label>
          <Form.Control
            as="select"
            name="approver2"
            value={formData.approver2}
            onChange={handleInputChange}
          >
             <option key={0} value={0}>Seleccionar aprobador</option>
           {approvers1.map((approver) => (
                <option key={approver.id} value={approver.id}>{approver.userName} - {approver.userRoles.name}</option>
              ))}
          </Form.Control>
        </Form.Group>
        <Row>
            <Col xl={12} className='text-center'>
                <Button className='m-3' variant="secondary" type="button" onClick={redirectBack} >
                    Cancelar o regresar
                </Button>
                <Button className='m-3' variant="primary" type="submit">
                    Crear usuario
                </Button>
            </Col>
        </Row>
      </Form>
      
      )}
      <ToastContainer />
    </div>
  );
}

export default AddUser