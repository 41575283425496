import { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

function RequestMovements({ requestId, show, handleClose, updateData }) {  
  let userId = localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')) : 0;


  const [formData, setFormData] = useState({
    requestId: 0,
    approved: true,
    comments: "",
    approverId: 0
  });  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    }
  const handleSave = () => {
    
    let validation = true;
    if(!formData.approved)
    {
        if(!formData.comments)
        {
            validation = false;
        }
    }
    if(validation)
    {      
      
        const requestOptions = {
            method: 'PUT', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
            body: JSON.stringify(formData) // Cuerpo de la solicitud convertido a JSON
          };
        fetch("/api/overtime/changeStatusApprover", requestOptions)
        .then(response => response.json())
        .then(data => {                          
        if(data.result)
        {
            toast.success('Solicitud actualizada correctamente');        
            updateData(); 
            handleClose();   
        }           
        else
        {
            toast.error('Lo sentimos, algo salió mal.');
        }
        })
        .catch(error => {            
            toast.error('Lo sentimos, algo salió mal.');
        });        
    }
    else
    {
        toast.warning(`Lo sentimos debes completar los siguientes campos: Comentarios`)
    }
  }
  useEffect(() => {
    setFormData({ ...formData, requestId: requestId, approverId: userId })
  }, [requestId]);

  return (
    <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Aprobar o rechazar tiempo extra</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
            <Col xl={6}>
            <Form.Group className='' controlId="">
                                    <Form.Label  >Cambiar estatus de la solicitud:</Form.Label>
                                    <Form.Check
                                        type="radio"
                                        label="Aprobar"
                                        id="approveRadio"
                                        name="approved"
                                        value="true"
                                        checked={formData.approved === true}
                                        onChange={() => setFormData({ ...formData, approved: true })}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Rechazar"
                                        name="Rechazar"
                                        id="rejectRadio"
                                        value="false"
                                        checked={formData.approved === false}
                                        onChange={() => setFormData({ ...formData, approved: false })}
                                    />
                                </Form.Group>
            </Col>
            <Col xl={6}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Comentarios</Form.Label>
                    <Form.Control 
                    as="textarea" 
                    rows={3}   
                    name="comments"
                    value={formData.comments}
                    onChange={handleInputChange}
                     />
                </Form.Group>
            </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button variant="primary" onClick={handleSave} >Guardar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RequestMovements;
