
import { useState, useEffect } from 'react';
import { Button, Table, Row, Col, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { getExtraPaymentToEdit, updateExtraPayment } from '../services/EditExtraPaymentModalService'
import Loading from '../components/Loading';


const getUserId = () => localStorage.getItem('userId') ?? 0;

function EditExtraPaymentModal({ show, handleClose, extraPaymentId }) {
  let userId = getUserId();
  const [loadingModal, setLoadingModal] = useState(true);
  const [formData, setFormData] = useState({
    extraPaymentId,
    pay: 0,
    reason: '',
    date: '',
    userId
  })
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveExtraPayment = () => {
    let pendingInputs = [];
    if(!formData.pay)
    {
      pendingInputs.push("Cantidad $")
    }
    if(!formData.reason)
    {
      pendingInputs.push("Razón")
    }
    if(pendingInputs.length == 0)
    {
     
      updateExtraPayment(formData).then(() => {
        toast.success('Solicitud actualizada correctamente');
        handleClose();
      });
    }
    else
    {
      toast.warning(`Lo sentimos, debes completar los siguientes campos: ${pendingInputs.join(', ')}`)
    }
  }
  useEffect(() => {
    if (extraPaymentId) {
        getExtraPaymentToEdit(extraPaymentId).then(data => {
        
            setFormData({
                extraPaymentId: data.id,
                pay: data.pay,
                reason: data.reason,
                date: data.formFormattedDate,
                userId
            })
        }).then(() => {
          setLoadingModal(false);
        })
    }
  }, [extraPaymentId]);

  return (
    <Modal size="sm" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Editar pago extraordinario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          {
              loadingModal ?
              <Loading />
                :
                <>
                  <Row>
                        <Col xl={12} className=' p-2'  >
                                <Form.Label>Ingrese razón del pago :</Form.Label>
                                    <Form.Group controlId="">
                                    <Form.Control
                                        as="textarea"
                                        rows={3} 
                                        name="reason"
                                        placeholder='Razón'
                                        value={formData.reason}
                                        onChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Col>
                </Row>
                <Row>
                        <Col xl={12} className=' p-2'  >
                                <Form.Label>Cantidad :</Form.Label>
                                    <Form.Group controlId="">
                                    <Form.Control
                                        type="text"
                                        name="pay"
                                        placeholder='Cantidad'
                                        value={formData.pay}
                                        onChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Col>
                        </Row>
                <Row>
                        <Col xl={12} className=' p-2'  >
                                <Form.Label>Fecha a la que corresponde:</Form.Label>
                                    <Form.Group controlId="formEmail">
                                    <Form.Control
                                      type="date"
                                      name="date"
                                        placeholder='Cantidad'
                                        value={formData.date}
                                        onChange={handleInputChange}
                                    />
                            </Form.Group>
                        </Col>
                  </Row>
        </>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSaveExtraPayment}>
            Guardar pago extra
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditExtraPaymentModal;
