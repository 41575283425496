
import React, { useEffect, useState } from 'react';
import { ListGroup, Form, Row, Col } from 'react-bootstrap';
import Loading from '../components/Loading';

function PeriodsList({  checkedItems, setCheckedItems, dates }){

    const onChangeSelectAll = (event) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            const allItems = dates.map(dtItem => dtItem.value);  // Seleccionar todos
            setCheckedItems(allItems);
        } else {
            setCheckedItems([]);  // Deseleccionar todos
        }
    };

    const onChangeIndividualCheckbox = (event) => {
        const { id, checked } = event.target;
        setCheckedItems(prevState => {
            if (checked) {
                // Agregar el valor si se selecciona
                return [...prevState, id];
            } else {
                // Quitar el valor si se deselecciona
                return prevState.filter(item => item !== id);
            }
        });
    };

    return (
        <>
        {
            (dates && dates.length > 0) ?
            (
                <>
                    <Row>
                        <Col>
                            <h4>Periodos</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className=''>
                            <Form.Check
                                className='m-3'
                                inline
                                onChange={onChangeSelectAll}
                                label="Seleccionar todo"
                                name="group1"
                                type="checkbox"
                                id="select_all"
                                checked={dates.length === checkedItems.length} // Seleccionar todo si todos están marcados
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ListGroup>
                            {
                                dates.map((dtItem, dtIndex) => (      
                                    <ListGroup.Item as="li" key={dtIndex}>
                                        <Form.Check
                                            inline
                                            label={dtItem.label}
                                            name="group1"
                                            type="checkbox"
                                            id={dtItem.value}
                                            checked={checkedItems.includes(dtItem.value)} // Estado del checkbox individual
                                            onChange={onChangeIndividualCheckbox} // Manejar cambio individual
                                        />
                                    </ListGroup.Item>
                                ))
                            }
                            </ListGroup>
                        </Col>
                    </Row>
                </>
            )
            :
            <Loading />
        }
        </>
    )
}

export default PeriodsList;