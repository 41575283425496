import React, { useEffect, useState } from 'react';
import { Table, Button, ListGroup, Badge, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ApproveRejectModal from '../components/ApproveRejectModal'
import RequestMovements from '../components/RequestMovements'
import EditItemModal from '../components/EditItemModal'
import EditExtraPaymentModal from '../components/EditExtraPaymentModal';
import Loading from '../components/Loading';

const getUserAndRole = () => {
    let userId = localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')) : 0;
    let roleId = localStorage.getItem('roleId') ? parseInt(localStorage.getItem('roleId')) : 0;
    return { userId, roleId }
}

const RequestsPendingApprovalTable = () => {
    const navigate = useNavigate();    
    let { userId, roleId } = getUserAndRole();
    const [reload, setReload] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);    
    const [requestId, setRequestId] = useState(0);    
    const [itemId, setItemId] = useState(0);    
    const [showForm, setShowForm] = useState(false);
    const [showMovements, setShowMovements] = useState(false);
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const [showEditExtraPaymentModal, setShowEditExtraPaymentModal] = useState(false); 
    const [extraPaymentId, setExtraPaymentId] = useState(0); 
    const handleApproveReject = (id) => {
        setRequestId(id)
        setShowForm(true);
    }
    const handleMovements = (id) => {
        setRequestId(id);
        setShowMovements(true);
    }
    const handleEditItem = (id) => {
        setItemId(id);
        setShowEditItemModal(true)
    }
    const handleEditExtraPayment = (id) => {
        setExtraPaymentId(id);
        setShowEditExtraPaymentModal(true);
    }
    const fetchData = () => {        
        setLoading(true);
        setError(null);
        fetch(`/api/overtime/getPendingPequests/${userId}/${roleId}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(list => {
                setData(list);
            })
            .catch(error => {
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const updateData = () => {
        fetchData();
    };
   

    useEffect(() => {
        fetchData();
    }, [ reload]);
    if (loading) return <Loading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div>
            <h2>Tiempo extra pendiente de aprobar:</h2>
            { 
            data.length == 0 ? (
                <Row key={0}>
                    <Col xl={12} >
                        <h4>No hay solicitudes pendientes</h4>
                    </Col>
                </Row>
            ) :
            data.map((item, index) => (
                <>
                <Row >
                    <Col xl={12} >
                        <Table className='mb-5 mt-3' striped bordered hover key={index} responsive>
                            <thead>
                                <tr className='table-primary'>
                                    <th colSpan={6}>   
                                        <Row>
                                            <Col md={3} className="text-center" >
                                                <h5>
                                                    Estatus: <Badge bg={item.statusColor}>   {item.status}</Badge>
                                                </h5>   
                                            </Col>
                                            <Col md={5} >
                                                <h5>
                                                    Periodo: <Badge bg="secondary"> {item.startDate} - {item.endDate}</Badge>
                                                </h5>   
                                            </Col>
                                            <Col md={4} >
                                                 <h5>
                                                    Usuario: <Badge bg="secondary"> {item.user}</Badge>
                                                </h5>   
                                            </Col>
                                        </Row>                             
                                                                    
                                    </th>
                                </tr>
                                <tr>
                                    <th className='text-center'></th>
                                    <th className='text-center'>Fecha</th>
                                    <th className='text-center'>Motivo</th>
                                    <th className='text-center'>Horas</th>
                                    <th className='text-center'>Trabajo desde casa</th>
                                    <th className='text-center'>A pagar</th>
                                </tr>
                            </thead>   
                            <tbody>  
                                {item.items.map((subItem, subIndex) => (            
                                        <tr>
                                                <td className='text-center'>
                                                    <Button onClick={() => handleEditItem(subItem.id)}  variant='warning' >Editar</Button>    
                                                </td>
                                                <td className='text-center'>{subItem.date} </td>
                                                <td className='text-center'>{subItem.reason}</td>
                                                <td className='text-center'>{subItem.hours}</td>
                                                <td className='text-center'> {subItem.itsWorkFromHome ? "Si" : "No"}</td>    
                                                <td className='text-center'>No aplica</td>                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                        </tr>
                                ))}
                                {item.formattedExtraPayments.map((subItem, subIndex) => (            
                                        <tr>
                                                <td className='text-center'>
                                                    <Button onClick={() => handleEditExtraPayment(subItem.id)}  variant='warning' >Editar</Button>    
                                                </td>
                                                <td className='text-center'>{subItem.formattedDate} </td>
                                                <td className='text-center'>{subItem.reason}</td>
                                                <td className='text-center'>N/A</td>
                                                <td className='text-center'>N/A</td>    
                                                <td className='text-center'>$ {subItem.pay}</td>                                                                                                                                                                                                                                                                                                                                                                                                                                                  
                                        </tr>
                                ))}

                            </tbody>          
                            <tfoot>
                                <tr>
                                    <td colSpan={2}>
                                        <Row> 
                                            <Col xl={12} className="d-grid mt-1 mb-1" >
                                                {
                                                    item.hasMovements ? (
                                                    <Button variant='info' onClick={() => handleMovements(item.id)}  >Ver movimientos</Button>
                                                    ) : null
                                                }         
                                            </Col>                                            
                                        </Row>                                     
                                    </td>
                                    <td colSpan={4}>
                                        <Row> 
                                            <Col xl={12} className="d-grid mt-1 mb-1" ><Button variant='warning' onClick={() => handleApproveReject(item.id)}  >Aprobar/Rechazar tiempo extra</Button></Col>                                            
                                        </Row>                                                                       
                                    </td>                                    
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
                <hr></hr>
                </>
            ))} 
           <ApproveRejectModal
                show={showForm}
                handleClose={() => setShowForm(false)}
                requestId={requestId}
                updateData={updateData}
            />
            <RequestMovements
                show={showMovements}
                handleClose={() => setShowMovements(false)}       
                requestId={requestId}
            />
            <EditItemModal
             show={showEditItemModal}
             handleClose={() => { setShowEditItemModal(false); setItemId(0); setReload(!reload); }}       
             itemId={itemId}
              />
              <EditExtraPaymentModal
               show={showEditExtraPaymentModal}
               handleClose={() => { setShowEditExtraPaymentModal(false); setItemId(0); setReload(!reload); }}
               extraPaymentId={extraPaymentId}
               
            />
              <ToastContainer />
        </div>
    );
};

export default RequestsPendingApprovalTable;
