import { Table, Button, Row, Col, Badge } from 'react-bootstrap';
import { useState } from 'react';


const RequestTable = (
    { 
        request, 
        handleMovements, 
        handleDetails, 
        pendingToPay, 
        handleOpenEditModal, 
        handleEditExtraPayment, 
        handleDeleteExtraPayment,
        handleAddExtraPayment
    }) => {
    const [enablePendingToPay, setEnablePendingToPay] = useState((pendingToPay == undefined || pendingToPay == null) ? false : pendingToPay);
    return (
            <Table className='mb-5 mt-3' border={3} striped bordered hover responsive>
                              <thead>
                                <tr className='table-primary'>
                                
                                <th colSpan={2}  >  
                                        <h6>
                                            Usuario <Badge bg="secondary">{request.user}</Badge>
                                        </h6>
                                    </th>
                                    <th colSpan={enablePendingToPay ? 3: 2} >
                                        <h6>
                                            Periodo <Badge bg="secondary"> {request.startDate} - {request.endDate}</Badge>
                                        </h6>    
                                    </th>       
                                    <th colSpan={2} >  
                                        <h6>
                                            Estatus <Badge bg={request.statusColor}>   {request.status}</Badge>
                                        </h6>   
                                    </th > 
                                </tr>
                                <tr>
                                    {
                                        enablePendingToPay ?
                                        <th className='text-center'></th> : 
                                        null
                                    }
                                    <th className='text-center'>Fecha</th>
                                    <th className='text-center'>Motivo</th>
                                    <th className='text-center'>Trabajo desde casa</th>
                                    <th className='text-center'>Horas solicitadas</th>
                                    <th className='text-center'>Horas a pagar</th>
                                    <th className='text-center'>Total a pagar por día</th>
                                </tr>
                            </thead>   
                <tbody>  
                             {request.items.map((subItem, subIndex) => (            
                                        <tr key={subIndex} >
                                                {
                                                    enablePendingToPay ?
                                                    <td className='text-center'>
                                                        <Button variant='link' onClick={() => handleOpenEditModal(subItem.id)} >Modificar pago de este día</Button>
                                                    </td> : 
                                                    null
                                                }
                                                <td className='text-center'>{subItem.date} ({subItem.dayOfTheWeek})</td>
                                                <td className='text-center'>{subItem.reason}</td>
                                                <td className='text-center'> {subItem.itsWorkFromHome ? "Si" : "No"}</td>       
                                                <td className='text-center'>{subItem.hours}</td>
                                                <td className='text-center'>{subItem.hoursToPay}</td>
                                                <td className='text-center d-flex justify-content-center '>
                                                { (subItem.modifiedPayment == null) ?  null: <p className="text-decoration-line-through">$ {subItem.moneyToPay} </p>}
                                                     <b className=''> $ {(subItem.modifiedPayment == null) ? subItem.moneyToPay: subItem.modifiedPayment}</b></td>
                                                    
                                                                                                                                                                                                                                          
                                        </tr>
                                ))}

                                    {request.formattedExtraPayments.map((subItem, subIndex) => (            
                                        <tr key={subIndex} >
                                                {
                                                    enablePendingToPay ?
                                                    <td className='text-center'>
                                                    <Row>
                                                        <Col xl={6} >
                                                            <Button variant='warning' size='sm' onClick={() => handleEditExtraPayment(subItem.id)} >Editar</Button>
                                                        </Col>
                                                        <Col xl={6}>
                                                            <Button variant='danger' size='sm' onClick={() => handleDeleteExtraPayment(subItem.id)} >Eliminar</Button>
                                                        </Col>
                                                    </Row>   
                                                    </td> : 
                                                    null
                                                }
                                                <td className='text-center'>{subItem.formattedDate}</td>
                                                <td className='text-center' >{subItem.reason}</td>
                                                <td className='text-center'> </td>       
                                                <td className='text-center'></td>
                                                <td className='text-center'></td>
                                                <td className='text-center d-flex justify-content-center '>
                                                { <p >$ {subItem.pay} </p> }
                                                </td>
                                                    
                                                                                                                                                                                                                                          
                                        </tr>
                                ))}
                </tbody> 
                <tfoot>
                    <tr className='table-primary'>
                        <td colSpan={1} className='text-center'>
                            {
                                request.hasMovements ? (
                                <Button variant='outline-dark' onClick={() => handleMovements(request.id)}  >Ver movimientos</Button>
                                ) : null
                            }
                        </td>
                        <td colSpan={1} className='text-center' >
                                <Button variant='outline-dark' onClick={() => handleDetails(request.id)}  >Ver detalles</Button>
                        </td>
                        {
                                enablePendingToPay ?
                                <td colSpan={1} className='text-center'>
                                     <Button variant='success' size='sm' onClick={() => handleAddExtraPayment(request.id)}  >Agregar pago extraordinario</Button>
                                </td> :
                                null
                        }
                        <td colSpan={2} className='text-end'>
                                        Total a pagar:
                                    </td>
                                    <td colSpan={1} className='text-center'>
                                        {request.hoursToPayTotal}
                                    </td>
                                    <td colSpan={1} className='text-center'>
                                        $ { (request.modifiedTotal == null) ?  request.totalToPay: request.modifiedTotal} 
                        </td>
                    </tr>
                </tfoot>         
            </Table>
    )
}
export default RequestTable;