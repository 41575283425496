import React, { useEffect, useState } from 'react'
import {  Button,Table, Spinner, Row, Col, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function EditUser(){
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [approvers1, setApprovers1] = useState([]);
    const [approvers2, setApprovers2] = useState([]);    
    const [formData, setFormData] = useState({
        email: '', 
        username: '',
        roleId: '0',
        approver1:0,
        approver2:0,
        weeklyPayment: '0',
        otHourCost: '0'        
      });
      const redirectBack = () => {
        navigate(`/users`);
      }
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handleSubmit = (e) => {
        e.preventDefault();             
        let email = formData.email;
        let userName = formData.username;        
        let roleId = parseInt(formData.roleId);   
        let approver1= parseInt(formData.approver1);
        let approver2 = parseInt(formData.approver2);
        let otHourCost = formData.otHourCost ? (parseFloat(formData.otHourCost) == NaN ? 0 : parseFloat(formData.otHourCost)) : 0;
        let weeklyPayment = formData.weeklyPayment ? (parseFloat(formData.weeklyPayment) == NaN ? 0 : parseFloat(formData.weeklyPayment)) : 0;
        
        if(email && userName && roleId)
        {                            
                const requestOptions = {
                    method: 'PUT', // Método de la solicitud
                    headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
                    body: JSON.stringify({ id, email, userName, roleId, approver1, approver2, otHourCost, weeklyPayment }) // Cuerpo de la solicitud convertido a JSON
                  };
                fetch("/api/users/updateUser", requestOptions)
                .then(response => response.json())
                .then(data => {                          
                if(data.result)
                {
                    toast.success('Usuario actualizado correctamente');
                }           
                else
                {
                    toast.error('Lo sentimos, algo salió mal.');
                }
                })
                .catch(error => {            
                    toast.error('Lo sentimos, algo salió mal.');
                });                       
        }
        else
        {
            console.log("else")
        }

      };
      
  useEffect(() => {
 
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/users/getUserToEdit/${id}`);
        const data = await response.json();
        console.log(data)
        setFormData({
            email: data.email || '',
            username: data.userName || '',
            roleId: data.roleId || 0,
            approver1: data.otApprover1 || 0,
            approver2: data.otApprover2 || 0,
            weeklyPayment: data.weeklyPayment || 0,
            otHourCost: data.otHrCost || 0            
          });
      } catch (error) {
        toast.error('Lo sentimos, algo salió mal.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();

    const fetchRoles = async () => {
        try {
          const response = await fetch(`/api/generalQueries/getRoles`);
          const data = await response.json();
          console.log(data)
          setRoles(data);
        } catch (error) {
            toast.error('Lo sentimos, algo salió mal.');
        } finally {
          //setIsLoading(false);
        }
      };
      fetchRoles();


      const fetchApprovers = async () => {
        try {
          const response = await fetch(`/api/users/getApproversAndAdmins`);
          const data = await response.json();
          console.log(data)
          setApprovers1(data);
          setApprovers2(data);
        } catch (error) {
            toast.error('Lo sentimos, algo salió mal.');
        } finally {
          setIsLoading(false);
        }
      };
      fetchApprovers();



  }, []);

  return (
    <div>
      { isLoading ? (
        <Spinner animation="border" role="status" />
      ) : (

        <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group className='mt-3' controlId="formUsername">
          <Form.Label>Username:</Form.Label>
          <Form.Control
            type="text"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            required
          />
        </Form.Group>
        <Form.Group className='mt-3' controlId="formWeeklyPayment">
          <Form.Label>Pago semanal *:</Form.Label>
          <Form.Control
            type="text"
            name="weeklyPayment"
            value={formData.weeklyPayment}
            onChange={handleInputChange}
            
          />
        </Form.Group>


        <Form.Group className='mt-3' controlId="formOtHourCost">
          <Form.Label>Costo del tiempo extra (Hora) *:</Form.Label>
          <Form.Control
            type="text"
            name="otHourCost"
            value={formData.otHourCost}
            onChange={handleInputChange}
            
          />
        </Form.Group>
        <Form.Group className='mt-3' controlId="formRole">
          <Form.Label>Rol:</Form.Label>
          <Form.Control
            as="select"
            name="roleId"
            value={formData.roleId}
            onChange={handleInputChange}
          >
           {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            {/* Añade más opciones según tus necesidades */}
          </Form.Control>
        </Form.Group>
        <Form.Group className='mt-3' controlId="formRole">
          <Form.Label>Aprobador de tiempo extra *:</Form.Label>
          <Form.Control
            as="select"
            name="approver1"
            value={formData.approver1}
            onChange={handleInputChange}
          >
             <option key={0} value={0}>Seleccionar aprobador</option>
           {approvers1.map((approver) => (
                <option key={approver.id} value={approver.id}>{approver.userName} - {approver.userRoles.name}</option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className='mt-3' controlId="formRole">
          <Form.Label>Aprobador de tiempo extra (Respaldo):</Form.Label>
          <Form.Control
            as="select"
            name="approver2"
            value={formData.approver2}
            onChange={handleInputChange}
          >
             <option key={0} value={0}>Seleccionar aprobador</option>
           {approvers1.map((approver) => (
                <option key={approver.id} value={approver.id}>{approver.userName} - {approver.userRoles.name}</option>
              ))}
          </Form.Control>
          </Form.Group>
        <Row>
            <Col xl={12} className='text-center'>
                <Button className='m-3' variant="secondary" type="button" onClick={redirectBack} >
                    Cancelar
                </Button>
                <Button className='m-3' variant="primary" type="submit">
                    Actualizar información
                </Button>
            </Col>
        </Row>
      </Form>
      
      )}
      <ToastContainer />
    </div>
  );
}

export default EditUser