import { Spinner, Row, Col } from 'react-bootstrap';

function Loading() {
  return (
    <Row className='mt-2'>
        <Col className='text-center'>
            <h6>Cargando...</h6>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Cargando...</span>
            </Spinner>
        </Col>
    </Row>
  );
}

export default Loading;