import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Breadcrumb from './Breadcrumb';
import { Container } from 'react-bootstrap';

const Layout = ({ children, routes }) => {
  return (
    <div className="d-flex flex-column min-vh-100 bg-light">
      <Header />
      <Container className="flex-grow-1 my-4">
        <Breadcrumb routes={routes} />
          {children}
        </Container  >
      <Footer />
    </div>
  );
};

export default Layout;
