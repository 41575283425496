export const getPaidRequests = async(dateValue) => {
    try {
        let obj = {
            dateValue
        }
        let url = "/api/overtime/getPaidRequests";
        const requestOptions = {
            method: 'POST', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
            body: JSON.stringify(obj) // Cuerpo de la solicitud convertido a JSON
          };
          const response = await fetch(url, requestOptions);
          const jsonResponse = await response.json();
          return jsonResponse; 
    } catch (error) {
        return {};
    }
}