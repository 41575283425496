import React, { useEffect, useState } from 'react';
import { getPaidRequests} from '../services/ReportsService'
import Loading from '../components/Loading';
import { Table,  Row, Col } from 'react-bootstrap';


function PaymentByPeriodTable({ dateValue, onUpdatePaymentData }) {

    const setting = { style: 'currency', currency: 'MXN', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const [requestResult, setRequestResult] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getPaidRequests(dateValue).then((requestsResult) => {
            setRequestResult(requestsResult);       
            onUpdatePaymentData({ dateValue, period: requestsResult.period, totalToPay: requestsResult.totalToPay });  
            setLoading()
        })
    },[])
    return (
        <>

        {
           
            (loading) ?
            <Loading /> :
            (
                <Row className='mt-3 mb-3'>
                    <Col>
                        <Table striped bordered border={2}>
                            <thead>
                                <tr class="table-dark"> 
                                    <td className='text-center' colSpan={3}>
                                        <h5>Periodo: {requestResult.period}</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='text-center'>Nombre</td>
                                    <td className='text-center'>Detalles</td>
                                    <td className='text-center'>Se pago</td>
                                </tr>
                            </thead>
                            <tbody>
                               {
                                    (requestResult && requestResult.requests) ?
                                    (
                                        requestResult.requests.map((request, requestIndex) => (
                                            <tr>
                                                <td className='text-center align-middle'>
                                                    {request.user}
                                                </td>
                                                <td className='text-center'>
                                                    {
                                                        (request.items) ?
                                                        ( 
                                                            request.items.map((item, itemIndex) => (   
                                                                <Row className=''>
                                                                     <Col className='text-left' style={{  fontSize: '12px' }}>                                                                        
                                                                        {item.date} ({item.dayOfTheWeek}) | Horas: {item.hoursToPay} | {item.reason}
                                                                    </Col>                                                                  
                                                                </Row>
                                                            )) 
                                                        ):
                                                        null
                                                    }
                                                    {
                                                        (request.formattedExtraPayments) ?
                                                        (
                                                            request.formattedExtraPayments.map((item, itemIndex) => (   
                                                                <Row className=''>
                                                                     <Col className='text-left' style={{  fontSize: '12px' }}>
                                                                        {item.formattedDate} | {item.reason}
                                                                    </Col>                                                                                                         
                                                                </Row>
                                                            )) 
                                                        ):
                                                        null
                                                    }
                                                </td>
                                                <td className='text-center align-middle' >
                                                    {request.modifiedTotal == null ? request.totalToPay.toLocaleString('es-MX',setting) : request.modifiedTotal.toLocaleString('es-MX',setting) }
                                                </td>
                                            </tr>
                                         ))  
                                    ):
                                    null
                               }
                            </tbody>
                            <tfoot>
                                <tr className='table-secondary' >
                                   <td colSpan={3} className="text-end" >
                                            <h5>Total del periodo: {
                                            requestResult.totalToPay ?
                                            requestResult.totalToPay.toLocaleString('es-MX',setting) :
                                            requestResult.totalToPay
                                            }</h5>
                                   </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </Col>
                </Row>
            )
            
        }
        </>
    )
}
export default PaymentByPeriodTable;