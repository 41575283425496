import React from 'react';
import { Navigate } from 'react-router-dom';
const OnlyAdmins = ({ children }) => {
  const token = localStorage.getItem('token');
  if(token)
  { 
     const roleId = parseInt(localStorage.getItem('roleId'));
    if(roleId == 3)
    {
        return children
    }
    else
    {
        return <Navigate to="/" />;
    }
  }
  else
  {
    return <Navigate to="/login" />;
  }
};

export default OnlyAdmins;