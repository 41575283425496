import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import HomeRouteCard from './components/HomeRouteCard'


// pendientes
//En la parte de pendiente de pagar no se puede eliminar
//en la parte donde se paga no esta el nombre de quien es el tiempo extra, solciitante, aprobador
//en el requestpendingtopay no refresca cuando se edita el cambio de pago

const getUserAndRole = () => {
    let userId = localStorage.getItem('userId') ? parseInt(localStorage.getItem('userId')) : 0;
    let roleId = localStorage.getItem('roleId') ? parseInt(localStorage.getItem('roleId')) : 0;
    return { userId, roleId }
}
const getRoutes = () => {
    let listToReturn = [];
    let { roleId } = getUserAndRole();
    let routes = [
        {
            roles: [3],
            title: "Usuarios",
            mainUrl: "/users",
            description: "Inactivar usuarios, Editar usuarios, actualizar información salarial y actualizar aprobadores.",
            features: [
                {
                    name: "Agregar usuario",
                    url: '/adduser'
                }
            ]

        },
        {
            roles: [1,2,3],
            title: "Solicitudes de tiempo extra pre-aprobadas",
            mainUrl: "/overtime",
            description: "Solicitar tiempo extra, ver estatus de las solicitudes, editar y eliminar solicitudes, ver movimientos",
            features: [
                
            ]

        },
        {
            roles: [2,3],
            title: "Aprobar tiempo extra",
            mainUrl: "/requestspendingapproval",
            description: "Aprobar tiempo extra, Editar tiempo extra.",
            features: [
               
            ]
        },
        {
            roles: [3],
            title: "Solicitudes pendientes de pagar",
            mainUrl: "/requestspendingtopay",
            description: "Ver solicitudes aprobadas, Cambiar estatus de las solicitudes a pagadas o rechazadas",
            features: [
               
            ]
        }
        ,
        {
            roles: [3],
            title: "Reportes",
            mainUrl: null,
            description: "",
            features: [
                {
                    name: "Reporte semanal",
                    url: "/AllRequests"
                },
                {
                    name: "Reporte general de pagos",
                    url: "/GeneralPaymentReport"
                } 
            ]
        }
    ]
    for(let route of routes)
    {
        if(route.roles && route.roles.includes(roleId))
        {
            listToReturn.push(route);
        }
    }
    return listToReturn;
}



function Home(){

    const [data, setData] = useState([]);
    useEffect(() => {
        setData(getRoutes());
    }, []);
  return (
    <Row>
         {
         data.map((item, index) => (
            <Col xl={3} >
                <HomeRouteCard element={item} />
            </Col>
         ))
        }

    </Row>
  )
}
export default Home