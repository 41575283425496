import React, { useEffect, useState } from 'react';
import { Button, Spinner, Row, Col, Form, Badge, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import AddExtraPaymentModal from '../components/AddExtraPaymentModal';
import EditExtraPaymentModal from '../components/EditExtraPaymentModal';
import RequestItemsTable from '../components/RequestItemsTable';
import { getUserName } from '../services/UsersService';

import  { 
    fetchQtyOfItemsByRequest, 
    fetchDatesBetween,
    fetchRequestById,
    returnToDraft,
    insertItem,
    getActiveUsers,
    getFormattedRequest,
    sendForApproval
}  from '../services/OverTimeRequestService';


const getDate = () => new Date().toISOString().slice(0, 10);
const getUserId = () => localStorage.getItem('userId') ?? 0;

const OverTimeRequest =  () => {
    let { id, usrId } = useParams();
    id = id ? parseInt(id) : 0;
    usrId = usrId ? parseInt(usrId) : 0;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [requestId, setRequestId] = useState(id);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentStatusId, setCurrentStatusId] = useState(1);    
    const [extraPaymentId, setExtraPaymentId] = useState(0);    
    const [userName, setUserName] = useState("");
    const date = getDate();
    const requesterId = getUserId();
    const [showAddExtraPaymentModal, setShowAddExtraPaymentModal] = useState(false); 
    const [showEditExtraPaymentModal, setShowEditExtraPaymentModal] = useState(false);   

    const handleShowAddModal = () => {
        setShowAddExtraPaymentModal(true)
    }
    const handleSendForApproval = () => {
        sendForApproval(requestId, formData.requesterId).then(sfaResult => {
            if(sfaResult.result)
            {
                navigate('/overtime')
            }
            else
            {
                toast.error('Lo sentimos, algo salió mal.');
            }
        })
    }

    const handleReturnToDraft = () => {

        returnToDraft(requestId, formData.requesterId).then(rtdResult => {
            if(rtdResult.result)
            {
                navigate('/overtime');
            }
            else
            {
                toast.error('Lo sentimos, algo salió mal.');
            }
        })
    }
    const [formData, setFormData] = useState({
        reason: '',
        datePic: date,
        hour: '1',
        requestId: id,
        userId: usrId,
        requesterId,
        workFromHome: false
    });
  
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleEditExtraPayment = (id) => {
        setExtraPaymentId(id);
        setShowEditExtraPaymentModal(true);
    }
    const redirectBack = () => {
        navigate(`/overtime`);
      }
    const formValidation = () => {        
        let pendingFields = [];
        if(!formData.reason)
        {
            pendingFields.push("Motivo del tiempo extra");
        }
        let hr = parseFloat(formData.hour);
        if(hr !== NaN && hr == 0)
        {
            pendingFields.push("Horas");
        }
        if(pendingFields.length == 0)
        {
            return true;
        }
        else
        {
            toast.warning(`Lo sentimos debes completar los siguientes campos: ${pendingFields.join(', ')}`)
            return false;
        }
    }
    const handleItemDeleted = () => {        
        fetchQtyOfItemsByRequest(id).then(qtyR=> {
            setIsButtonDisabled(qtyR)
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        if(formValidation())
        {
            const existId = formData.requestId !== 0;
            insertItem(
                existId,
                formData.requestId, 
                formData
            ).then(iiResult => {
                if(iiResult.result)
                {
                    if (!existId) {
                        setFormData(prevFormData => ({
                            ...prevFormData,
                            requestId: iiResult.requestId,
                            reason: '',
                            hour: '1',
                        }));
                        setRequestId(iiResult.requestId);
                    }
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        reason: '',
                        hour: '1',
                    }));
                    setReload(prevReload => prevReload + 1);
                    toast.success('Usuario creado correctamente');
                }
                else
                {
                    toast.error('Lo sentimos, algo salió mal.');
                }
                
            })

          
        }
        
    };

    useEffect(() => {        
        fetchQtyOfItemsByRequest(requestId).then(qtyR=> {            
            setIsButtonDisabled(qtyR)
        });
        fetchDatesBetween().then(datesBetween => {
            setStartDate(datesBetween.previousWednesdayFormatted)
            setEndDate(datesBetween.nextWednesdayFormatted)            
        });
        fetchRequestById(requestId).then(requestData => {
            if(requestData)
            {
                
                setCurrentStatusId(requestData.statusId)
            }
        });
        if(requestId !== 0)
        {
            getFormattedRequest(requestId).then(formattedRequestData => {
                setUserName(formattedRequestData.user)
            });
        }
        else
        {
            getUserName(usrId).then(userData => {
                setUserName(userData.userName)
            })
        }
    }, [requestId, reload]);

    return (
        <div>
            <Row>
                {
                    requestId === 0 ? 
                    null : 
                    (
                        <Col xl="4">
                            <h6>
                                Usuario: <Badge bg="secondary">{userName}</Badge>
                            </h6>
                        </Col>
                    )
                }
                <Col xl="4">
                    <h6>
                        Periodo: <Badge bg="secondary">{startDate} - {endDate}</Badge>
                    </h6>
                </Col>
            </Row>
            {isLoading ? (
                <Spinner animation="border" role="status" />
            ) : (
                <>
            
                    <Form onSubmit={handleSubmit}>
                      <Row>
                      <Col xl={4}>
                      <Card>
                                <Card.Header>Usuario</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <b>Nombre:</b> {userName}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button className='m-4' variant="success" onClick={handleShowAddModal} >
                                                Agregar pago extraordinario +
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                      </Col>
                        <Col xl={8}>
                            <Card>
                                <Card.Header>Solicitar pago de tiempo extra</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xl={8} >
                                            <Form.Group className='mt-3' controlId="formReason">
                                                <Form.Label>Motivo del tiempo extra:</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={1}
                                                    name="reason"
                                                    placeholder='Evento, pendiente, Tarea, etc.'
                                                    value={formData.reason}
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xl="4">
                                        <Form.Group className='mt-3' controlId="formReason">
                                            <Form.Label>Fecha del tiempo extra:</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="datePic"
                                                value={formData.datePic}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    </Row>
                                    <Row>
                                    
                                    <Col xl="4">
                                        <Form.Group className='mt-3' controlId="formReason">
                                            <Form.Label>Horas:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="hour"
                                                value={formData.hour}
                                                onChange={handleInputChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xl="4">
                                        <Form.Group className='mt-3' controlId="formWorkFromHome">
                                            <Form.Label>Trabajo desde casa:</Form.Label>
                                            <Form.Check
                                                type="radio"
                                                label="Sí"
                                                name="workFromHome"
                                                value="true"
                                                checked={formData.workFromHome === true}
                                                onChange={() => setFormData({ ...formData, workFromHome: true })}
                                            />
                                            <Form.Check
                                                type="radio"
                                                label="No"
                                                name="workFromHome"
                                                value="false"
                                                checked={formData.workFromHome === false}
                                                onChange={() => setFormData({ ...formData, workFromHome: false })}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xl="4" className='d-flex justify-content-md-center'>
                                        <Button className='m-4' variant="success" type="submit">
                                            Agregar +
                                        </Button>
                                    </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                       
                    </Row>
                        
                    </Form>

                    <Row>
                        <Col>
                            <RequestItemsTable id={requestId} reload={reload} onDeleteSuccess={handleItemDeleted}  handleEditExtraPayment={handleEditExtraPayment} />
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col xl={6} className='text-center d-grid gap-2'>
                            <Button className='m-1' onClick={redirectBack} variant='secondary'>Cancelar</Button>
                        </Col>
                        <Col xl={6} className='text-center d-grid gap-2'>
                        { currentStatusId === 1 ? (
                            <Button className='m-1' disabled={isButtonDisabled} onClick={handleSendForApproval} variant='success'>
                                Enviar a aprobación
                            </Button>
                        ) : currentStatusId === 2 ? (
                            <Button  className='m-1' variant='info' onClick={handleReturnToDraft} >
                                Regresar solicitud a borrador
                            </Button>
                        ) : (
                            <Button className='m-1' disabled variant='secondary'>
                                Acción no disponible
                            </Button>
                        )}
                        </Col>
                    </Row>
                </>
            )}
            <ToastContainer />
            <AddExtraPaymentModal
                show={showAddExtraPaymentModal}
                handleClose={() => { setShowAddExtraPaymentModal(false);}}
                requestId={formData.requestId}
                userId={formData.userId}
                handleReload={() => { 
                    setReload(!reload);
                    toast.success("Registro creado correctamente")
                  }}
                updateRequestId={(urId) => { 
                    setRequestId(urId);  
                    setReload(!reload); 
                    setFormData(prevFormData => ({
                        ...prevFormData,
                        requestId: urId,
                    }));
                }}
            />
            <EditExtraPaymentModal
               show={showEditExtraPaymentModal}
               handleClose={() => { setShowEditExtraPaymentModal(false); }}
               extraPaymentId={extraPaymentId}
               handleReload={() => { 
                setReload(!reload);
                toast.success("Registro actualizado correctamente")
             }}
            />


          
        </div>
    );
};

export default OverTimeRequest;
