export const deleteExtraPayment = async(id, userId) =>{
    try {
        let url = `/api/overtime/deleteExtraPay/${id}/${userId}`;
        const requestOptions = {
            method: 'DELETE', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
        };
        const response = await fetch(url, requestOptions);
        const jsonResponse =  await response.json();
        return jsonResponse.result;
    } catch (error) {
        return false;
    }
}

export const updateRequestsAsPay = async(userId, selectedIds, comments) => {
    try {
        let obj = {
            userId,
            requestsIds: selectedIds,
            comments
        }
        let url = "/api/overtime/updateRequestsAsPay";
        const requestOptions = {
            method: 'PUT', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
            body: JSON.stringify(obj) // Cuerpo de la solicitud convertido a JSON
          };
          const response = await fetch(url, requestOptions);
          const jsonResponse = await response.json();
          return jsonResponse.result; 
    } catch (error) {
        return false;
    }
}

export const getRequestsToPay = async() => {
    try {
        let url = `/api/overtime/requestsToPay`;
        const requestOptions = {
            method: 'GET', // Método de la solicitud
            headers: { 'Content-Type': 'application/json' }, // Cabeceras de la solicitud
        };
        const response = await fetch(url, requestOptions);
        return await response.json();
    } catch (error) {
        return [];
    }
    
}
 