import React, { useEffect, useState } from 'react'
import {  Button,Table, Spinner, Row, Col, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Users(){
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const handleEdit = (id) => {        
        navigate(`/editUser/${id}`);
      };
      const addUserRedirect = () => {
        navigate(`/AddUser`);
      } 
      const handleInactive = (id) => {
        const requestOptions = {
            method: 'PUT', 
            headers: { 'Content-Type': 'application/json' },           
          };
        fetch(`/api/users/inactivateUser/${id}`, requestOptions)
        .then(response => response.json())
        .then(data => {                          
        if(data.result)
        {
            toast.success('Usuario actualizado correctamente');
            fetchData();
        }           
        else
        {
            toast.error('Lo sentimos, algo salió mal.');
        }
        })
        .catch(error => {            
            toast.error('Lo sentimos, algo salió mal.');
        });     
      }
      const handleActive = (id) => {
        const requestOptions = {
            method: 'PUT', 
            headers: { 'Content-Type': 'application/json' },           
          };
        fetch(`/api/users/activateUser/${id}`, requestOptions)
        .then(response => response.json())
        .then(data => {                          
        if(data.result)
        {
            toast.success('Usuario actualizado correctamente');
            fetchData();
        }           
        else
        {
            toast.error('Lo sentimos, algo salió mal.');
        }
        })
        .catch(error => {            
            toast.error('Lo sentimos, algo salió mal.');
        });
      }
      const fetchData = async () => {
        try {
          const response = await fetch('/api/users/getAll');
          const data = await response.json();
          setData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setIsLoading(false);
        }
      };
  useEffect(() => {    
    fetchData();
  }, []);

  return (
    <div>
      <h2>Usuarios</h2>
      {isLoading ? (
        <Spinner animation="border" role="status" />
      ) : (
        
      <>
        <Row>
            <Col className='text-left'>
                <Button className='m-3' variant="success" type="button" onClick={addUserRedirect} >
                    Agregar usuario +
                </Button>
            </Col>
        </Row>
        <Row>
            <Col>
            <Table  striped bordered hover responsive>
                <thead>
                    <tr>
                    <th className='text-center'>#</th>
                    <th className='text-center'></th>
                    <th className='text-center'></th>
                    <th className='text-center'>Nombre</th>
                    <th className='text-center'>Tipo de usuario</th>
                    <th className='text-center'>Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                    <tr key={item.id}>
                        <td className='text-center'>{item.id}</td>
                        <td className='text-center'><Button onClick={() => handleEdit(item.id)} variant="link" >Editar</Button></td>
                        <td className='text-center'>
                            { 
                                item.isActive ? 
                                <Button onClick={() => handleInactive(item.id)} variant="danger" >Inactivar</Button> :
                                <Button onClick={() => handleActive(item.id)} variant="success" >Activar</Button>
                            }
                            
                        </td>
                        <td className='text-center'>{item.userName}</td>
                        <td className='text-center'>{item.userRoles.name}</td>
                        <td className='text-center'>{item.isActive ?   <Badge bg="success">Activo</Badge> :   <Badge bg="danger">Inactivo</Badge>}</td>
                    </tr> 
                    ))}
                </tbody>
            </Table>
            </Col>
        </Row>
        </>
      )}
          <ToastContainer />
    </div>
  );
}

export default Users