import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Badge, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import RequestMovements from '../components/RequestMovements'
import AddExtraPaymentModal from '../components/AddExtraPaymentModal';
import EditExtraPaymentModal from '../components/EditExtraPaymentModal';
import ChangeItemPaymentModal from '../components/ChangeItemPaymentModal'
import RequestTable from '../components/RequestTable'
import RequestDetailsModal from '../components/RequestDetailsModal'
import Loading from '../components/Loading';
import { updateRequestsAsPay, getRequestsToPay, deleteExtraPayment } from '../services/RequestsPendingToPayService'


const getUserId = () => localStorage.getItem('userId') ?? 0;

const RequestsPendingApprovalTable = () => {
    const userId = parseInt(getUserId());
    const navigate = useNavigate();   
    const [reload, setReload] = useState(false)     
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);    
    const [showMovements, setShowMovements] = useState(false);    
    const [showChangePaymentModal, setShowChangePaymentModal] = useState(false);    
    const [showAddExtraPaymentModal, setShowAddExtraPaymentModal] = useState(false); 
    const [showEditExtraPaymentModal, setShowEditExtraPaymentModal] = useState(false);   
    const [currentRequestId, setCurrentRequestId] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [itemId, setItemId] = useState(0);
    const [extraPaymentId, setExtraPaymentId] = useState(0);
    const [showDetails, setShowDetails] = useState(false);  
    const [comments, setComments] = useState("");
    const handleDetails = (id) => {
        setCurrentRequestId(id);
        setShowDetails(true);
    }
    const handleCommentsChange = (e) => {
        const {  value } = e.target;
        setComments(value);
    }
    const handleSave = () => {
        let pendingFiels = [];
        if(selectedIds.length == 0)
        {
            pendingFiels.push("Es necesario seleccionar al menos un empleado");
        }
        if(!comments)
        {
            pendingFiels.push("Comentarios");
        }
        if(pendingFiels.length == 0)
        {
       
            updateRequestsAsPay(userId, selectedIds, comments).then(uResult => {
                if(uResult)
                {
                    toast.success('Pagos actualizados correctamente');
                    fetchData()
                    setComments("");
                    setSelectedIds([]);
                }
                else
                {
                    toast.error('Lo sentimos, algo salió mal.');
                }
            }) 
        }
        else
        {
            toast.warning(`Lo sentimos, debes completar los siguientes campos: ${pendingFiels.join(', ')}`)
        }        
    }
    const handleCheckboxChange = (id) => {
        setSelectedIds(prevState => 
            prevState.includes(id) 
                ? prevState.filter(selectedId => selectedId !== id) 
                : [...prevState, id]
        );
    };
    const handleDeleteExtraPayment = (id) => {
        deleteExtraPayment(id,userId).then(()=> {
            fetchData();
        })
    }
    const handleEditExtraPayment = (id) => {
        console.log(id)
        setExtraPaymentId(id);
        setShowEditExtraPaymentModal(true);
    }
    const handleOpenEditModal = (id) => {
        setItemId(id);
        setShowChangePaymentModal(true)

    }
    const handleAddExtraPayment = (id) => {
        setCurrentRequestId(id);
        setShowAddExtraPaymentModal(true)
    }
    const handleMovements = (id) => {
        setCurrentRequestId(id);
        setShowMovements(true);
    }

    const fetchData = () => {        
        setLoading(true);
        setError(null);
        getRequestsToPay().then(list => {
            setData(list);
        }).finally(() => {
            setLoading(false);
        })
    };

   

    useEffect(() => {
        fetchData();
    }, [reload]);
    if (loading) return <Loading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div>
            <h2>Tiempo extra pendiente de pagar:</h2>

            { 
            data.requests &&  data.requests.length != 0 ?
            <>
            <Row>
                <Col xl={12}>
                    <h5 className='mt-5'>Resumen:</h5>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                        <Table  className='mt-1 mb-5' border={3} responsive>
                        <thead>
                            <tr className='table-primary'>
                                <th className='text-center'>Marcar como pagado</th>
                                <th className='text-center'>Nombre</th>
                                <th className='text-center'>Horas</th>
                                <th className='text-center'>Pago</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.requests.map((item, index) => (
                                <tr key={index}>
                                    <td className='text-center'>
                                        <input
                                            type="checkbox"
                                            checked={selectedIds.includes(item.id)}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        />
                                    </td>
                                    <td className='text-center'>{item.user}</td>
                                    <td className='text-center'>{item.hoursToPayTotal}</td>
                                    <td className='text-center d-flex justify-content-center'>
                                        { (item.modifiedTotal == null || item.modifiedTotal  == 0) ?  null: <p className="text-decoration-line-through">$ {item.totalToPay} </p>}
                                        <b className=''> $ {(item.modifiedTotal == null || item.modifiedTotal  == 0) ? item.totalToPay: item.modifiedTotal}</b></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className='table-success'>
                                <td className='text-end' colSpan={2} >Totales:</td>
                                <td className='text-center'>{data.totalHours}</td>
                                <td className='text-center'>$ {data.totalToPay}</td>
                            </tr>
                            <tr className='table-secondary'>
                                 <td className='text-center' colSpan={2} >
                                    <Row>
                                        <Col>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Comentarios</Form.Label>
                                            <Form.Control 
                                            as="textarea" 
                                            rows={2}   
                                            name="comments"
                                            value={comments}
                                            onChange={handleCommentsChange}
                                            />
                                        </Form.Group>
                                        </Col>
                                    </Row>
                                </td>          
                                <td className='text-center'colSpan={2} >
                                    <Row>
                                        <Col className='' xl={12}>
                                            <Button onClick={handleSave} variant="primary">Actualizar solicitudes seleccionadas como pagadas</Button>
                                        </Col>
                                    </Row>                                
                                </td>
                                                      
                                             
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
             
            
            </>
            :null
                    }


            { 

data.requests && data.requests.length == 0 ? (
                <Row >
                    <Col xl={12} >
                        <h4>No hay solicitudes pendientes</h4>
                    </Col>
                </Row>
            ) :
               
            data.requests.map((item, index) => (
                <>         
                <Row className='mt-3' key={index} >
                    <Col xl={12} >

                        <RequestTable 
                        request={item} 
                        handleMovements={() => { handleMovements(item.id) }} 
                        pendingToPay={true} 
                        handleOpenEditModal={handleOpenEditModal}
                        handleEditExtraPayment={handleEditExtraPayment} 
                        handleDeleteExtraPayment={handleDeleteExtraPayment}
                        handleAddExtraPayment={handleAddExtraPayment}
                        handleDetails={handleDetails}
                        />
                    </Col>
                </Row>
                <hr></hr>
                
                </>
            ))
        } 
           
                                   
              <ToastContainer />
              <RequestMovements
                show={showMovements}
                handleClose={() => setShowMovements(false)}
                requestId={currentRequestId}
                />
                
                <AddExtraPaymentModal
                show={showAddExtraPaymentModal}
                handleClose={() => { setShowAddExtraPaymentModal(false); setCurrentRequestId(0); }}
                requestId={currentRequestId}
                handleReload={() => { setReload(!reload) }}
            />
            <RequestDetailsModal
                show={showDetails}
                handleClose={() => setShowDetails(false)}
                requestId={currentRequestId}
              />
            <EditExtraPaymentModal
                show={showEditExtraPaymentModal}
                handleClose={() => { setShowEditExtraPaymentModal(false); }}
                extraPaymentId={extraPaymentId}
                handleReload={() => { setReload(!reload) }}
            />  
                 <ChangeItemPaymentModal
                show={showChangePaymentModal}
                handleClose={() => setShowChangePaymentModal(false)}
                itemId={itemId}
                  handleReload={() => { setReload(!reload) }}
                 />
        </div>
    );
};

export default RequestsPendingApprovalTable;
