import React, { useEffect, useState } from 'react';
import { Table, Button, Form, Badge, Row, Col } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import RequestMovements from '../components/RequestMovements'
import RequestTable from '../components/RequestTable'
import RequestDetailsModal from '../components/RequestDetailsModal';
import Loading from '../components/Loading';
import { getGroupedDates } from '../services/OverTimeRequestService';
import { PDFLink } from './Reportv2';

const RequestsPendingApprovalTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);    
    const [showMovements, setShowMovements] = useState(false);    
    const [showDetails, setShowDetails] = useState(false);    
    const [currentRequestId, setCurrentRequestId] = useState(null);    
    const [statusId, setStatusId] = useState(0);
    const [status, setStatus] = useState([]);
    const [users, setUsers] = useState([]);    
    const [dates, setDates] = useState([]);
    const [selectedDate, setSelectedDate] = useState(0);

    
    const handleSelectStatusChange = (event) => {
        setStatusId(parseInt(event.target.value));
    }
    const handleSelectPeriodChange = (event) => {
        setSelectedDate(event.target.value);
    }
    
    const handleMovements = (id) => {
        setCurrentRequestId(id);
        setShowMovements(true);
    }
 
    const handleDetails = (id) => {
        setCurrentRequestId(id);
        setShowDetails(true);
    }
    const fetchData = () => {        
        setLoading(true);
        setError(null);
        let url = `/api/overtime/getRequestsByDatesAndStartsId`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ statusId, selectedDate })
        };
        fetch(url, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(list => {
                console.log('Data fetched:', list);
                setData(list);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const fetchStatus = async () => {
        try {
          const response = await fetch(`/api/generalQueries/getActiveStatus`);
          const data = await response.json();
          setStatus(data);
        } catch (error) {
            toast.error('Lo sentimos, algo salió mal.');
        } finally {
        }
      };
      const fetchUsers = async () => {
        try {
          const response = await fetch(`/api/users/getAll`);
          const data = await response.json();
          setUsers(data);
        } catch (error) {
            toast.error('Lo sentimos, algo salió mal.');
        } finally {
        }
      };
      useEffect(() => {
        fetchStatus();
        fetchData();
        fetchUsers();
        getGroupedDates().then((dtes) => {
            setDates(dtes)
        })
    }, []);
      useEffect(() => {
        fetchData();
    }, [statusId, selectedDate]);
    
    if (loading) return <Loading />;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <div>
            <h2>Solicitudes:</h2>
            <Row>
                <Col xl={8}>
                    <Form.Group>
                        <Form.Label>Periodos</Form.Label>
                        <Form.Select aria-label="Default select example" value={selectedDate} onChange={handleSelectPeriodChange} >
                            <option key={0} value="0">Seleccionar</option>
                            {dates.map((dt) => (
                                <option key={dt.value} value={dt.value}>{dt.label}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xl={4} >
                <Form.Group>
                        <Form.Label>Estatus de la solicitud</Form.Label>
                        <Form.Select aria-label="Default select example" value={statusId} onChange={handleSelectStatusChange}>
                            <option key={0} value="0">Seleccionar</option>
                            {status.map((stat) => (
                                <option key={stat.id} value={stat.id}>{stat.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Col>                
            </Row>
            {
                (data.requests.length == 0 ?
                    null:
                    <PDFLink data={data} />)
            }
            { 
            


            data.requests.length == 0 ? (
                <Row className='mt-5' >
                    <Col xl={12} className='text-center mt-5' >
                        <h4>Es necesario seleccionar el periodo y estatus</h4>
                    </Col>
                </Row>
            ) :
                                  
            data.requests.map((item, index) => (
                <>         
                <Row className='mt-3' key={index} >
                    <Col xl={12} >
                        <RequestTable request={item} handleMovements={() => { handleMovements(item.id) }} handleDetails={() => { handleDetails(item.id) }} />
                    </Col>
                </Row>
                <hr></hr>
                
                </>
            ))
            } 
            { 
            
            data.requests.length != 0 ?
            <>
            <Row>
                <Col xl={12}>
                    <h5 className='mt-5'>Resumen:</h5>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                        <Table  className='mt-1 mb-5' border={3} responsive>
                        <thead>
                            <tr className='table-primary'>
                                <th className='text-center'>Nombre</th>
                                <th className='text-center'>Horas</th>
                                <th className='text-center'>Pago</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.requests.map((item, index) => (
                                <tr key={index}>
                                    <td className='text-center'>{item.user}</td>
                                    <td className='text-center'>{item.hoursToPayTotal}</td>
                                    <td className='text-center d-flex justify-content-center'>
                                        { (item.modifiedTotal == null || item.modifiedTotal  == 0) ?  null: <p className="text-decoration-line-through">$ {item.totalToPay} </p>}
                                        <b className=''> $ {(item.modifiedTotal == null || item.modifiedTotal  == 0) ? item.totalToPay: item.modifiedTotal}</b></td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr className='table-success'>
                                <td className='text-end'>Totales:</td>
                                <td className='text-center'>{data.totalHours}</td>
                                <td className='text-center'>$ {data.totalToPay}</td>
                            </tr>
                        </tfoot>
                    </Table>
                </Col>
            </Row>
             
            
            </>
            :null
                    }
                                   
              <ToastContainer />
              <RequestMovements
                show={showMovements}
                handleClose={() => setShowMovements(false)}
                requestId={currentRequestId}
              />
                <RequestDetailsModal
                show={showDetails}
                handleClose={() => setShowDetails(false)}
                requestId={currentRequestId}
              />
        </div>
    );
};

export default RequestsPendingApprovalTable;
