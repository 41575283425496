import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import EditItemModal from '../components/EditItemModal'
import Loading from '../components/Loading';
import {  deleteExtraPayment } from '../services/RequestsPendingToPayService';

const getUserId = () => localStorage.getItem('userId') ?? 0;
const RequestItemsTable = ({ id, reload, onDeleteSuccess, handleEditExtraPayment}) => {
    const userId = parseInt(getUserId());
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [itemId, setItemId] = useState(0);    
    const [showEditItemModal, setShowEditItemModal] = useState(false);
    const fetchData = () => {
        setLoading(true);
        setError(null);
        fetch(`/api/overtime/getItemsByRequestId/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(list => {
                console.log('Data fetched:', list);
                setData(list);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleEditItem = (id) => {
        setItemId(id);
        setShowEditItemModal(true)
    }

    const handleDeleteExtraPayment = (id) => {
        deleteExtraPayment(id,userId).then(result => {
            if (result) {
                toast.success('Registro eliminado correctamente');
                fetchData(); // Recargar la tabla después de eliminar
                if (onDeleteSuccess) {
                    onDeleteSuccess(); // Notificar al componente padre
                }
            } else {
                toast.error('Lo sentimos, algo salió mal.');
            }
        })
    }
    const handleDelete = (itemId) => {
        const url = `/api/overtime/deleteItem/${itemId}`;
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.result) {
                    toast.success('Registro eliminado correctamente');
                    fetchData(); // Recargar la tabla después de eliminar
                    if (onDeleteSuccess) {
                        onDeleteSuccess(); // Notificar al componente padre
                    }
                } else {
                    toast.error('Lo sentimos, algo salió mal.');
                }
            })
            .catch((error) => {
                console.error('Error deleting item:', error);
                toast.error('Lo sentimos, algo salió mal.');
            });
    };

    useEffect(() => {
        fetchData();
    }, [reload]);

    if (loading) return <Loading />;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            <Table className='mt-3' striped bordered responsive>
                <thead>
                    <tr>
                        <th className='text-center'></th>
                        <th className='text-center'></th>
                        <th className='text-center'>Motivo</th>
                        <th className='text-center'>Horas</th>
                        <th className='text-center'>Fecha</th>
                        <th className='text-center'>Trabajo desde casa</th>
                        <th className='text-center'>A pagar</th>
                       
                    </tr>
                </thead>
                <tbody>
                    {data.regularItems.map((item, index) => (
                        <tr key={index}>
                            <td className='text-center'>
                                <Button onClick={() => handleDelete(item.id)} type="button" variant='danger'>Eliminar</Button>
                            </td>
                            <td className='text-center'>
                                                    <Button onClick={() => handleEditItem(item.id)}  variant='warning' >Editar</Button>    
                            </td>
                            <td className='text-center'>{item.reason}</td>
                            <td className='text-center'>{item.hours}</td>
                            <td className='text-center'>{item.formattedDate}</td>
                            <td className='text-center'>{item.itsWorkFromHome ? "Si" : "No"}</td>
                            <td className='text-center'>No disponible</td>
                        </tr>
                    ))}
                    {data.formattedExtraPayments.map((item, index) => (
                        <tr key={index}>
                            <td className='text-center'>
                                <Button onClick={() => handleDeleteExtraPayment(item.id)} type="button" variant='danger'>Eliminar</Button>
                            </td>
                            <td className='text-center'>
                                <Button onClick={() => handleEditExtraPayment(item.id)}  variant='warning' >Editar</Button>    
                            </td>
                            <td className='text-center'>{item.reason}</td>
                            <td className='text-center'>N/A</td>
                            <td className='text-center'>{item.formattedDate}</td>
                            <td className='text-center'>N/A</td>
                            <td className='text-center'>$ {item.pay}</td>
                        </tr>
                    ))}
                
                </tbody>
            </Table>
            <EditItemModal
             show={showEditItemModal}
             handleClose={() => { setShowEditItemModal(false); setItemId(0) }}       
             itemId={itemId}
              />
        </div>
        
    );
};

export default RequestItemsTable;
