import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="bg-dark text-white text-center mt-auto py-3">
      <Container>
        <span>© 2024 Calculame La Nomina. Código-9</span>
      </Container>
    </footer>
  );
};

export default Footer;
