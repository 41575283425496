import { useState, useEffect } from 'react';
import { Button, Table, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import React from 'react';
function RequestMovements({ show, handleClose, requestId }) {
  const [data, setData] = useState(null);    
  useEffect(() => {
    if (requestId) {
      fetch(`/api/overtime/getMovementsByRequestId/${requestId}`)
        .then(response => response.json())
        .then(data => setData(data))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [requestId]);



  const formatText = (text) => {
    const lines = text.trim().split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line.split('\t').map((subLine, subIndex) => (
          <React.Fragment key={subIndex}>
            {subIndex > 0 && <span>&emsp;&emsp;</span>}
            {subLine}
          </React.Fragment>
        ))}
        {index < lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };
  

  return (
    <Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Movimientos de la Solicitud</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
           <Row>
            <Col xl={12}>
            <Table className='mb-5 mt-3' striped bordered hover>
                <thead>                               
                    <tr>
                        <th className='text-center'>Fecha</th>
                        <th className='text-center'>Usuario</th>
                        <th className='text-center'>Movimiento</th>
                        <th className='text-center'>Comentarios</th>
                    </tr>
                </thead> 
                <tbody>  
                { data.map((item, index) => (
                     <tr key={index} className={index == 0 ? "table-success" : null}>
                        <td className='text-center'>{item.datentime} </td>
                        <td className='text-center'>{item.user}</td>
                        <td className=''>     {formatText(item.status)}</td>
                        <td className='text-center'> {item.comments}</td>                                                                                                                                                                                                                           
                     </tr>
                ))} 
                </tbody>  
            </Table>
            </Col>
           </Row>
         
        ) : (
          'Cargando datos...'
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RequestMovements;
