import React, { useState } from 'react';
import { Navbar, Nav, Container, Button, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const getUserName = () => {
  return localStorage.getItem('userName') ?? "";
}
const Header = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState(getUserName);
  const handleLogout = () => {    
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('userName');
    localStorage.removeItem('roleId');          
    navigate('/login');
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">Calculame la nomina</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/">Inicio</Nav.Link>                     
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
        <Badge bg="info" text="dark">
          Conectado como: {userName}
         </Badge>   
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">       
            <Button className="m-2" variant="outline-light" onClick={handleLogout}>
              Cerrar sesión
            </Button>          
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
