
import { useState, useEffect } from 'react';
import { Button, Table, Row, Col, Form} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { addExtraPayment } from '../services/AddExtraPaymentModalService'
 

const getDate = () => new Date().toISOString().slice(0, 10);
const getUserId = () => localStorage.getItem('userId') ?? 0;
function AddExtraPaymentModal({ show, handleClose, requestId, handleReload, userId, updateRequestId }) {
  let requesterId = getUserId();
  const [newPay, setNewPay] = useState("");
  const [reason, setReason] = useState("");
  const [datePic, setDatePic] = useState(getDate()); 
  const handleDate = (e) => {
    const { value } = e.target;
    setDatePic(value)
  }
  const handleReason = (e) => {
    const { value } = e.target;
    setReason(value);
  }
  const handleNewPayChange = (e) => {
    const { value } = e.target;
    if (!isNaN(value) && value !== '') {
        setNewPay(value);
    } else {
        setNewPay('0');
    }
};
  const handleSaveExtraPayment = () => {
    let pendingInputs = [];
    if(!newPay)
    {
      pendingInputs.push("Cantidad $")
    }
    if(!reason)
    {
      pendingInputs.push("Razón")
    }
    if(pendingInputs.length == 0)
    {
      addExtraPayment({ payment: newPay, reason, date: datePic, userId, requesterId, requestId }).then(aepR => {
        handleClose();
        handleReload();
        updateRequestId(aepR.requestId);
      });
    }
    else
    {
      toast.warning(`Lo sentimos, debes completar los siguientes campos: ${pendingInputs.join(', ')}`)
    }
  }
  useEffect(() => {
    if (requestId) {
      setNewPay('');
      setReason('')
      setDatePic(getDate())
    }
  }, [show, requestId]);

  return (
    <Modal size="sm" show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Agregar pago extraordinario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
              <Col xl={12} className=' p-2'  >
                      <Form.Label>Ingrese razón del pago :</Form.Label>
                          <Form.Group controlId="">
                          <Form.Control
                              as="textarea"
                              rows={3} 
                              name="reason"
                              placeholder='Razón'
                              value={reason}
                              onChange={handleReason}
                          />
                  </Form.Group>
              </Col>
       </Row>
       <Row>
              <Col xl={12} className=' p-2'  >
                      <Form.Label>Cantidad :</Form.Label>
                          <Form.Group controlId="">
                          <Form.Control
                              type="text"
                              name="newPay"
                              placeholder='Cantidad'
                              value={newPay}
                              onChange={handleNewPayChange}
                          />
                  </Form.Group>
              </Col>
              </Row>
       <Row>
              <Col xl={12} className=' p-2'  >
                      <Form.Label>Fecha a la que corresponde:</Form.Label>
                          <Form.Group controlId="formEmail">
                          <Form.Control
                            type="date"
                            name="datePic"
                              placeholder='Cantidad'
                              value={datePic}
                              onChange={handleDate}
                              required
                          />
                  </Form.Group>
              </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleSaveExtraPayment}>
            Guardar pago extra
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddExtraPaymentModal;
