import Breadcrumb from 'react-bootstrap/Breadcrumb';

function BreadcrumbExample({ routes }) {
  return (
    <Breadcrumb>
       {
         !routes || routes.length ==  0 ? null : 
         routes.map((item, index) => (
            (
                item.isActive ? 
                <Breadcrumb.Item active key={index} href={item.url}>{item.name}</Breadcrumb.Item> :
                <Breadcrumb.Item  key={index} href={item.url}>{item.name}</Breadcrumb.Item>
            )
         
         ))
       }
     
     
    </Breadcrumb>
  );
}

export default BreadcrumbExample;