import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './pages/Login';
import RequestRecoverPassword from './pages/RequestRecoverPassword';
import Users from './pages/Users';
import EditUser from './pages/EditUser';
import AddUser from './pages/AddUser';
import Layout from './components/Layout';
import RequestsPendingToPay from './pages/RequestsPendingToPay';
import OverTime from './pages/OverTime';
import AllRequests from './pages/AllRequests';
import GeneralPaymentReport from './pages/GeneralPaymentReport';
import ResetPassword from './pages/ResetPassword';
import RequestsPendingApproval from './pages/RequestsPendingApproval';
import OverTimeRequest from './pages/OverTimeRequest';
import 'bootstrap/dist/css/bootstrap.min.css';
import PrivateRoute from './components/PrivateRoute';
import OnlyAdmins from './components/OnlyAdmins';
import OnlyApprovers from './components/OnlyApprovers';
import Report from './pages/Report';


const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>     

      <Route path="/users" element={
          <OnlyAdmins>
          <Layout routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Usuarios", url: "/users", isActive: true }, { name: "Agregar usuario", url: "/AddUser", isActive: false }]} >
            <Users />
          </Layout> 
        </OnlyAdmins>
      } /> 
      <Route path="/adduser" element={
          <OnlyAdmins>
          <Layout routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Usuarios", url: "/users", isActive: false }, { name: "Agregar usuario", url: "/AddUser", isActive: true }]} >
            <AddUser />
          </Layout> 
        </OnlyAdmins>
      } /> 
        
 <Route path="/editUser/:id" element={
          <OnlyAdmins>
          <Layout routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Usuarios", url: "/users", isActive: false }, { name: "Editar usuario", url: "", isActive: true }]} >
            <EditUser />
          </Layout> 
        </OnlyAdmins>
      } /> 
      <Route path="/overtime" element={
                <PrivateRoute>
                <Layout routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Mis solicitudes de tiempo extra", url: "/overtime", isActive: true }, { name: "Solicitar pago de tiempo extra", url: "/overtimerequest", isActive: false }]} >
                  <OverTime />
                </Layout> 
              </PrivateRoute>
            } /> 
      <Route path="/overtimerequest/:id/:usrId?" element={
          <PrivateRoute>
          <Layout routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Mis solicitudes de tiempo extra", url: "/overtime", isActive: false }, { name: "Solicitar pago de tiempo extra", url: "/overtimerequest", isActive: true }]} >
            <OverTimeRequest />
          </Layout> 
        </PrivateRoute>
      } /> 
   <Route path="/requestspendingapproval" element={
          <OnlyApprovers>
          <Layout routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Tiempo extra pendiente de aprobar", url: "/requestspendingapproval", isActive: true }]}  >
            <RequestsPendingApproval />
          </Layout> 
        </OnlyApprovers>
      } /> 
    <Route path="/requestspendingtopay" element={
          <OnlyAdmins>
          <Layout routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Tiempo extra pendiente de pagar", url: "/requestspendingtopay", isActive: true }]} >
            <RequestsPendingToPay />
          </Layout> 
        </OnlyAdmins>
      } /> 
    <Route path="/allrequests" element={
              <OnlyAdmins>
              <Layout routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Todas las solicitudes", url: "/AllRequests", isActive: true }]} >
                <AllRequests />
              </Layout> 
            </OnlyAdmins>
          } /> 



      <Route path="/GeneralPaymentReport" element={
              <OnlyAdmins>
              <Layout  routes={[{ name: "Inicio", url: "/", isActive: false }, { name: "Reporte general de pagos", url: "/GeneralPaymentReport", isActive: true }]} >
                <GeneralPaymentReport />
              </Layout> 
            </OnlyAdmins>
          } /> 


      <Route path="/" element={
          <PrivateRoute>
          <Layout >
            <Home />
          </Layout> 
        </PrivateRoute>
      } />       

<Route path="/report" element={
          <PrivateRoute>
          <Layout >
            <Report />
          </Layout> 
        </PrivateRoute>
      } />   
      <Route path="/requestRecoverPassword" element={<RequestRecoverPassword />} />

        <Route path="/login" element={<Login />} />
        <Route path="/ResetPassword/:id" element={<ResetPassword />} />


      </Routes>
    </Router>
  </React.StrictMode>
); 